import React, { memo, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar

// import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from "../routes.js";

import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";

import bgImage from "../assets/img/misa.jpg";
import AuthContext from "../Context/Auth/AuthContext.js";
import AlertaContext from "../Context/Alerta/AlertaContext.js";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Cargando from "../components/Extra/Cargando.js";

// eslint-disable-next-line
import dashboardRoutes from "../routes.js";
import CenizaContext from "../Context/Cenizas/CenizaContext.js";
import MisasContext from "../Context/Misas/MisasContext.js";
import InhumacionesContext from "../Context/Inhumaciones/InhumacionesContext.js";
import ServiciosContext from "../Context/Servicios/ServiciosContext.js";

// import AuthContext from "../Context/auth/AuthContext";

const useStyles = makeStyles(styles);

const Admin = ({ ...rest }) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const { usuario } = useContext(AuthContext);

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const color = "green";
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerOpen = () => {
    setdrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setdrawerOpen(false);
  };
  // eslint-disable-next-line
  const [drawerOpen, setdrawerOpen] = React.useState(false);

  const { alerta } = useContext(AlertaContext);
  const { socket } = useContext(AuthContext);

  const {
    agregarCeniza,
    editarCeniza,
    eliminarCeniza,
    rows: rowsCenizas,
    obtenerCenizas,
  } = useContext(CenizaContext);
  const {
    agregarMisa,
    editarMisa,
    eliminarMisa,
    obtenerMisas,
    rows: rowsMisas,
  } = useContext(MisasContext);
  const {
    agregarInhumacion,
    editarInhumacion,
    eliminarInhumacion,
    obtenerInhumaciones,
    rows: rowsInhumaciones,
  } = useContext(InhumacionesContext);
  const {
    agregarServicio,
    editarServicio,
    eliminarServicio,
    obtenerServicios,
    rows: rowsServicios,
  } = useContext(ServiciosContext);

  useEffect(() => {
    socket.on("guardadoExitoso", (data) => {
      if (!usuario) {
        return;
      }
      const row = JSON.parse(data);

      if (row.tabTipo === "cenizas") {
        agregarCeniza(row);
      }
      if (row.tabTipo === "misas") {
        agregarMisa(row);
      }
      if (row.tabTipo === "inhumaciones") {
        agregarInhumacion(row);
      }
      if (row.tabTipo === "servicios") {
        agregarServicio(row);
      }

      // setmessages((messages) => [...messages, message]);
    });
    socket.on("actualizadoExitoso", (data) => {
      if (!usuario) {
        return;
      }
      const row = JSON.parse(data);

      if (row.tabTipo === "cenizas") {
        editarCeniza(row);
      }
      if (row.tabTipo === "misas") {
        editarMisa(row);
      }
      if (row.tabTipo === "inhumaciones") {
        editarInhumacion(row);
      }
      if (row.tabTipo === "servicios") {
        editarServicio(row);
      }

      // setmessages((messages) => [...messages, message]);
    });

    socket.on("eliminadoExitoso", (data) => {
      if (!usuario) {
        return;
      }
      if (data.tipo === "cenizas") {
        eliminarCeniza(data.id);
      }
      if (data.tipo === "misas") {
        eliminarMisa(data.id);
      }
      if (data.tipo === "inhumaciones") {
        eliminarInhumacion(data.id);
      }
      if (data.tipo === "servicios") {
        eliminarServicio(data.id);
      }
    });
    //eslint-disable-next-line
  }, [usuario]);

  //obteniendo datos
  useEffect(() => {
    if (!rowsCenizas && usuario) {
      obtenerCenizas(usuario.agencia, usuario.subAgencia);
    }
    if (!rowsInhumaciones && usuario) {
      obtenerInhumaciones(usuario.agencia, usuario.subAgencia);
    }
    if (!rowsMisas && usuario) {
      obtenerMisas(usuario.agencia, usuario.subAgencia);
    }
    if (!rowsServicios && usuario) {
      obtenerServicios(usuario.agencia, usuario.subAgencia);
    }
    // eslint-disable-next-line
  }, [usuario]);

  if (!rowsServicios) {
    return <Cargando titulo="Cargando Servicios" />;
  }

  if (!rowsMisas) {
    return <Cargando titulo="Cargando Misas" />;
  }

  if (!rowsInhumaciones) {
    return <Cargando titulo="Cargando asignaturas" />;
  }
  if (!rowsCenizas) {
    return <Cargando titulo="Cargando Cenizas" />;
  }

  if (!socket) {
    return null;
  }

  if (!usuario) return <Cargando titulo="cargando usuario" />;

  return (
    <div className={classes.wrapper}>
      {alerta ? (
        <Box display="flex" justifyContent="center">
          <Alert
            style={{ position: "absolute", zIndex: "8086" }}
            severity={alerta.categoria}
            variant="filled"
          >
            {alerta.msg}
          </Alert>
        </Box>
      ) : null}
      <Sidebar
        routes={routes}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />
      <div
        className={drawerOpen ? classes.mainPanel : classes.mainPanelDrawer}
        ref={mainPanel}
      >
        <Navbar
          drawerOpen={drawerOpen}
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {dashboardRoutes.map((prop, key) => {
                if (prop.layout === "/monteolivo") {
                  return (
                    <Route
                      path={prop.layout + prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                }
                return null;
              })}
              <Redirect from="/monteolivo" to="/monteolivo/Servicios" />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default memo(Admin);

// {
//   rowsSubCategorias.map((prop, key) => {
//     if (!prop.categoria) {
//       return (
//         <Route
//           path={prop.layout + "/" + prop.pagina}
//           component={prop.componente}
//           key={key}
//         />
//       );
//     }
//     if (prop.layout === "/monteolivo") {
//       return (
//         <Route
//           path={prop.layout + "/" + prop.categoria.nombre + "/" + prop.pagina}
//           component={prop.componente}
//           key={key}
//         />
//       );
//     }
//     return null;
//   });
// }

// {dashboardRoutes.map((prop, key) => {
//   if (prop.layout === "/monteolivo") {
//     return (
//       <Route
//         path={prop.layout + prop.path}
//         component={prop.component}
//         key={key}
//       />
//     );
//   }
//   return null;
// })}
