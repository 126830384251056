export const validarEmail = (email) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return true;
  } else {
    return false;
  }
};
export const calcularEdad = (fecha) => {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return edad;
};
export const ordenar = (orden, desc) => (a, b) => {
  const rowa = JSON.parse(a.tabInformacion);
  const rowb = JSON.parse(b.tabInformacion);
  const numa = rowa[orden] === "" ? 0 : Date.parse(rowa[orden]);
  const numb = rowb[orden] === "" ? 0 : Date.parse(rowb[orden]);
  if (desc) {
    if (rowa[orden] === "" || rowb[orden] === "") {
      return numb - numa;
    }
    return numb - numa;
  } else {
    if (rowa[orden] === "" || rowb[orden] === "") {
      return numb - numa;
    }
    return numa - numb;
  }
};
export const fechaEspañol = (fecha) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
    hour: "2-digit",
    minute: "2-digit",
  };

  const fechaEspañol = new Date(fecha);
  return fechaEspañol.toLocaleDateString("es-ES", {
    ...options,
  });
};
export const isIsoDate = (value, mostrarAlerta, campo) => {
  //si cumple regex
  if (value === "") {
    return false;
  }
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(value)) {
    mostrarAlerta(`Fecha no valida (${campo})`, "error");
    return true;
  } else {
    const length = value.length;

    if (length > 16) {
      mostrarAlerta(
        `La fecha tiene mas caracteres de lo habitual (${campo})`,
        "error"
      );
      return true;
    }

    //parte 1
    const FechaP1 = value.split("T")[0];
    const añoMesDia = FechaP1.split("-");

    const mes = añoMesDia[1];

    if (Number(mes) > 12) {
      mostrarAlerta(`La año no tiene mas de 12 meses (${campo})`, "error");
      return true;
    }
    const dia = añoMesDia[2];

    if (Number(dia) > 31) {
      mostrarAlerta(`Los meses no tienen mas de 31 dias (${campo})`, "error");
      return true;
    }
    //parte 2
    const FechaP2 = value.split("T")[1];
    const HoraMin = FechaP2.split(":");
    const hora = HoraMin[0];
    if (Number(hora) > 23) {
      mostrarAlerta(`El dia no tiene mas de 24 horas (${campo})`, "error");
      return true;
    }
    const minuto = HoraMin[1];
    if (Number(minuto) > 59) {
      mostrarAlerta(`La hora no tiene mas de 60 minutos (${campo})`, "error");
      return true;
    }
    //sin errores
    return false;
  }
};
