import React, { memo, useState, useContext } from "react";

import { Box } from "@material-ui/core";

import BarraTablas from "../../components/BarraTablas/BarraTablas";
import TablaServicios from "../../components/Tablas/Inhumaciones/index";
import ModalServicios from "../../components/Tablas/Inhumaciones/Modal";
import InhumacionesContext from "../../Context/Inhumaciones/InhumacionesContext";

const Inhumaciones = () => {
  const [editar, seteditar] = useState(false);
  const [open, setopen] = useState(false);

  const [buscando, setbuscando] = useState(false);
  const [buscados, setbuscados] = useState([]);
  const [filtro, setfiltro] = useState("");
  const { rows } = useContext(InhumacionesContext);
  const handleSearch = () => {
    setbuscados(
      rows.filter((row) => {
        return row.tabInformacion.toLowerCase().includes(filtro.toLowerCase());
      })
    );
  };
  return (
    <Box>
      <BarraTablas
        filtro={filtro}
        setfiltro={setfiltro}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={setopen}
        open={open}
      />
      <TablaServicios
        setopen={setopen}
        rows={rows}
        buscando={buscando}
        buscados={buscados}
        seteditar={seteditar}
      />
      <ModalServicios
        setopen={setopen}
        open={open}
        seteditar={seteditar}
        editar={editar}
      />
    </Box>
  );
};

export default memo(Inhumaciones);
