import React, { useReducer, memo } from "react";
import clienteAxios from "../../config/axios";
import {
  OBTENER_SERVICIOS,
  AGREGAR_SERVICIO,
  EDITAR_SERVICIO,
  ELIMINAR_SERVICIO,
  CHANGE_SERVICIOS_ACTIVO,
  CERRAR_SESION,
} from "../../types/index";

//context
import ServiciosContext from "./ServiciosContext";
//reducer
import ServiciosReducer from "./ServiciosReducer";
const ServciciosState = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "agencia",
        label: "agencia",
        minWidth: 150,
      },
      {
        id: "cedula",
        label: "cedula",
        minWidth: 150,
      },
      {
        id: "nombres",
        label: "nombres",
        minWidth: 150,
      },
      {
        id: "servicio",
        label: "servicio",
        minWidth: 150,
      },
      {
        id: "sala",
        label: "sala",
        minWidth: 150,
      },
      {
        id: "inicio",
        label: "inicio",
        minWidth: 150,
      },
      {
        id: "misa",
        label: "misa",
        minWidth: 150,
      },
      {
        id: "ubicacion",
        label: "ubicacion",
        minWidth: 150,
      },
      {
        id: "asesor",
        label: "asesor",
        minWidth: 150,
      },
      {
        id: "observacion",
        label: "observacion",
        minWidth: 150,
      },
    ],
    servicioActivo: {
      tipo: "servicios",
      agencia: "MO",
      informacion: {
        cedula: "",
        nombres: "",
        servicio: "",
        sala: "",
        inicio: "",
        misa: "",
        ubicacion: "",
        asesor: "",
        estado: "ACTIVO",
        user: "",
        idAsesor: "",
        observacion: "",
      },
    },
  };

  const [state, dispatch] = useReducer(ServiciosReducer, initialState);

  const obtenerServicios = async (agencia, subagencia = "") => {
    console.log({
      tipo: "servicios",
      agencia,
      subagencia,
    });
    const res = await clienteAxios.post("/tablero", {
      tipo: "servicios",
      agencia,
      subagencia,
    });
    [...res.data].forEach((item) => {
      try {
        JSON.parse(item.tabInformacion);
      } catch (error) {}
    });
    dispatch({
      type: OBTENER_SERVICIOS,
      payload: res.data,
    });
  };

  const agregarServicio = async (servicio) => {
    try {
      dispatch({
        type: AGREGAR_SERVICIO,
        payload: servicio,
      });
    } catch (error) {}
  };

  const editarServicio = async (servicioEditado) => {
    try {
      dispatch({
        type: EDITAR_SERVICIO,
        payload: servicioEditado,
      });
    } catch (error) {}
  };

  const eliminarServicio = async (id) => {
    try {
      dispatch({
        type: ELIMINAR_SERVICIO,
        payload: id,
      });
    } catch (error) {}
  };

  const changeServicioActivo = (asignatura) => {
    dispatch({
      type: CHANGE_SERVICIOS_ACTIVO,
      payload: asignatura,
    });
  };
  const cerrarSesionServicios = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };
  return (
    <ServiciosContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        servicioActivo: state.servicioActivo,
        // funciones
        obtenerServicios,
        agregarServicio,
        editarServicio,
        eliminarServicio,
        changeServicioActivo,
        cerrarSesionServicios,
      }}
    >
      {props.children}
    </ServiciosContext.Provider>
  );
};

export default memo(ServciciosState);
