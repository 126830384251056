import React, { useContext, useState, useEffect, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
// import ModalAgregarAcreditacion from "./ModalAgregarAcreditacion";
// import Row from "./Row";

// import AcreditacionSaldoContext from "../../../Context/AcreditacionSaldo/AcreditacionSaldoContext";
// import ModalAgregarAcreditacion from "./ModalAgregarAcreditacion";

import { withRouter } from "react-router-dom";
// import Spinner from "../../Spinner/Spinner";

import Row from "./Row";

import Cargando from "../../Extra/Cargando";
import InhumacionesContext from "../../../Context/Inhumaciones/InhumacionesContext";
import { ordenar } from "../../../config/validaciones";
import AuthContext from "../../../Context/Auth/AuthContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 800,
  },
});
const TablaAsignaturas = (props) => {
  const { setopen, rows, buscando, buscados, seteditar } = props;

  const {
    columns,

    obtenerInhumaciones,
    // rowsSearch
  } = useContext(InhumacionesContext);

  //columnas y filas de la tabla

  // eslint-disable-next-line
  const clases = useStyles();
  // eslint-disable-next-line

  // eslint-disable-next-line
  const [nombres, setnombres] = useState("");

  let filas = [];
  // eslint-disable-next-line

  if (buscando) {
    filas = buscados;
  } else {
    filas = rows;
  }

  // let arreglo = [];

  //   if (habilitarBusqueda) {
  //     arreglo = rowsSearch;
  //   } else {
  //     arreglo = rows;
  //   }

  const [orden, setorden] = useState("fecha");
  const [ascendiente, setascendiente] = useState(false);

  const handleCLick = (e) => {
    setorden(e);
  };
  //clases
  const classes = useStyles();
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const {
    usuario: { agencia, subAgencia },
  } = useContext(AuthContext);
  useEffect(() => {
    //eslint-disable-next-line
    console.log(rows);
    //eslint-disable-next-line
    if (!rows) {
      console.log(agencia, subAgencia);
      obtenerInhumaciones(agencia, subAgencia);
    }

    // eslint-disable-next-line
  }, []);
  //si no hay usuarios //filas no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando Inhumaciones" />;
  }

  return (
    <Paper className={classes.root}>
      {/* <ModalAgregarAcreditacion
        haSidoAceptado={haSidoAceptado}
        sethaSidoAceptado={sethaSidoAceptado}
        open={open}
        setopen={setopen}
      /> */}

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
          {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
          <TableHead>
            <TableRow>
              <TableCell size="small" padding="checkbox"></TableCell>
              {columns.map((column, index) => {
                return (
                  <TableCell
                    key={column.id && index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    padding="none"
                  >
                    <Button
                      variant={orden === column.id ? "contained" : "text"}
                      color={orden === column.id ? "primary" : "secondary"}
                      className={classes.boton}
                      fullWidth
                      onClick={() => {
                        if (
                          !column.id ||
                          column.id === "curso" ||
                          column.id === "especialidad"
                        ) {
                          return;
                        }
                        // setascendiente(!ascendiente);
                        // handleCLick(column.id);
                      }}
                      startIcon={
                        orden === column.id ? (
                          ascendiente ? (
                            <KeyboardArrowDown color="inherit" />
                          ) : (
                            <KeyboardArrowUp color="inherit" />
                          )
                        ) : null
                      }
                      endIcon={
                        orden === column.id ? (
                          ascendiente ? (
                            <KeyboardArrowDown color="inherit" />
                          ) : (
                            <KeyboardArrowUp color="inherit" />
                          )
                        ) : null
                      }
                    >
                      {column.label}
                    </Button>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filas
              .sort(ordenar("fecha", true))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

              .map((row, index) => {
                return (
                  <Row
                    key={row.tabId}
                    row={row}
                    index={index}
                    setopen={setopen}
                    seteditar={seteditar}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={filas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default memo(withRouter(TablaAsignaturas));
