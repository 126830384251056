import React, { useState, useContext, useEffect, memo } from "react";
import {
  Modal,
  makeStyles,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import Draggable from "react-draggable";

import { Cancel } from "@material-ui/icons";
// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";

import ServiciosContext from "../../../Context/Servicios/ServiciosContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { Autocomplete } from "@material-ui/lab";
import { isIsoDate } from "../../../config/validaciones";
import { formato, trimPropsItem } from "../../../config/const";

function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",

    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 750,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "8vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  estudianteDatos: {
    padding: 0,
    flexGrow: 1,
    margin: theme.spacing(0.5),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
}));

const ModalAsignatura = (props) => {
  const {
    servicioActivo,
    changeServicioActivo, // eslint-disable-next-line
  } = useContext(ServiciosContext);

  const { servicios, salas, usuario, socket, asesores } =
    useContext(AuthContext);
  console.log({ salas });
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  //props
  const { open, setopen, seteditar, editar } = props;

  const [servicio, setservicio] = useState(servicioActivo);
  const { informacion } = servicio;

  const submit = () => {
    if (
      informacion.cedula.trim() !== "" &&
      informacion.nombres.trim() !== "" &&
      informacion.servicio.trim() !== "" &&
      informacion.sala.trim() !== "" &&
      informacion.ubicacion.trim() !== "" &&
      informacion.asesor.trim() !== ""
    ) {
      return true;
    }
    return false;
  };

  const defaultActive = () => {
    changeServicioActivo({
      tipo: "servicios",
      agencia: "MO",
      informacion: {
        cedula: "",
        nombres: "",
        servicio: "",
        sala: "",
        inicio: "",
        misa: "",
        ubicacion: "",
        asesor: "",
        estado: "ACTIVO",
        user: "",
        idAsesor: "",
        observacion: "",
      },
    });
    setopen(false);
    seteditar(false);
  };
  useEffect(() => {
    setservicio(servicioActivo);
  }, [servicioActivo]);
  const handleChange = (e) => {
    console.log([e.target.name], e.target.value);
    setservicio({
      ...servicio,
      informacion: {
        ...servicio.informacion,
        [e.target.name]: e.target.value,
      },
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años
  const [
    ocultar,
    // setocultar
  ] = useState(false);

  const handleCrear = async () => {
    if (isIsoDate(informacion.misa, mostrarAlerta, "Misa")) {
      return;
    }
    if (isIsoDate(informacion.inicio, mostrarAlerta, "Inicio")) {
      return;
    }

    socket.emit("guardarData", {
      ...servicio,
      informacion: trimPropsItem({
        ...servicio.informacion,
        user: usuario.usuario,
      }),
      agencia: usuario.agencia,
      subagencia: usuario.subAgencia,
    });
    // agregarAsignatura(servicio);
    defaultActive();
  };

  const handleEditar = () => {
    if (isIsoDate(informacion.misa, mostrarAlerta, "Misa")) {
      return;
    }
    if (isIsoDate(informacion.inicio, mostrarAlerta, "Inicio")) {
      return;
    }

    socket.emit("actualizarData", {
      ...servicio,
      informacion: trimPropsItem({
        ...servicio.informacion,
        user: usuario.usuario,
      }),
      tipo: servicio.tabTipo,
      agencia: servicio.tabAgencia,
      id: servicio.tabId,
      subagencia: usuario.subAgencia,
    });
    // editarAsignatura(servicio);
    defaultActive();
  };

  if (!servicios) {
    return null;
  }
  if (!salas) {
    return null;
  }
  if (!asesores) {
    return null;
  }
  return (
    <Modal
      open={open}
      onClose={() => {
        // setocultar(!ocultar);
      }}
    >
      <Draggable handle="#handle">
        <Box display="flex" justifyContent="center">
          <div
            style={ocultar ? modalStyleOculto : modalStyle}
            className={clases.paper}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              style={{ cursor: "-webkit-grab" }}
            >
              <Typography
                id="handle"
                variant="button"
                color="initial"
                align="center"
              >
                {editar ? "Editar" : "Agregar"} servicio.
              </Typography>
              <Box display="flex" alignItems="center">
                <IconButton
                  aria-label=""
                  style={{ margin: "0px" }}
                  onClick={() => {
                    defaultActive();
                  }}
                >
                  <Cancel color="secondary" />
                </IconButton>
              </Box>
            </Box>

            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Switch
                    name="estado"
                    checked={informacion.estado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setservicio({
                        ...servicio,
                        informacion: {
                          ...servicio.informacion,
                          estado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        },
                      });
                    }}
                  />
                }
                label="Estado."
              />
              <Box display={"flex"}>
                <Typography variant="body1" color="primary">
                  Agencia:{" "}
                  <Typography color="secondary" component={"span"}>
                    {usuario.agencia}
                  </Typography>
                </Typography>
                <Box mx={1}></Box>
                <Typography variant="body1" color="primary">
                  Sub-Agencia:{" "}
                  <Typography color="secondary" component={"span"}>
                    {usuario.subAgencia}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems=" stretch"
              width="100%"
            >
              <Box></Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              borderRadius="0px 50px 50px 0px"
              mb={2}
              p={1}
            >
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="cedula"
                label="Cédula"
                value={informacion.cedula}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />{" "}
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="nombres"
                label="Nombres"
                value={informacion.nombres}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="servicio"
                label="Servicio"
                value={informacion.servicio}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
                select
              >
                {servicios.map((row) => (
                  <MenuItem value={row.detalle}>{row.detalle} </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="sala"
                label="Sala"
                value={informacion.sala}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
                select
              >
                {salas.map((row) => (
                  <MenuItem value={row.nomsala}> {row.nomsala} </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                onChange={(e) => {
                  handleChange(e);
                }}
                className={clases.estudianteDatos}
                name="inicio"
                label={`Inicio ${formato}`}
                value={informacion.inicio}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="datetime-local"
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="misa"
                label={`Misa ${formato}`}
                value={informacion.misa}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="datetime-local"
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="ubicacion"
                label="Ubicación"
                value={informacion.ubicacion}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />{" "}
              <FormControl className={clases.estudianteDatos} fullWidth>
                <Autocomplete
                  options={asesores.map(
                    (asesor) => asesor.nomase + "/" + asesor.codase
                  )}
                  getOptionLabel={(option) => option.split("/")[0]}
                  id="controlled-demo"
                  value={informacion.asesor}
                  name="a"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setservicio({
                        ...servicio,
                        informacion: {
                          ...servicio.informacion,
                          asesor: newValue.split("/")[0],
                          idAsesor: newValue.split("/")[1],
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asesor"
                      size="small"
                      required
                      fullWidth
                      name="a"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
              <TextField
                multiline
                rows={3}
                variant="outlined"
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="observacion"
                label="Observación"
                value={informacion.observacion}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
            </Box>
            {/* <Divider /> */}
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  disabled={!submit()}
                  color="primary"
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Draggable>
    </Modal>
  );
};

export default memo(ModalAsignatura);
// {
// 	"tipo": "inhumaciones",
//    "agencia": "MO",
// 	"informacion": {
// 		"cedula": "1716520000",
//       "nombres": "",
// 		"servicio": "",
// 		"fecha": "",
// 		"ubicacion": "",
// 		"asesor": "",
// 		"estado": "",
// 		"user": ""
// 	}
// },
