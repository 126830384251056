import React, { memo, useContext } from "react";

import {
  makeStyles,
  Button,
  Box,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Add, Backspace, FindInPage } from "@material-ui/icons";
import IconBreadcrumbs from "../Extra/MigasDePan";
import AlertaContext from "../../Context/Alerta/AlertaContext";

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      margin: "2px",
      width: "100%",
    },
    margin: theme.spacing(1),
  },
  margin: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "100%",
    },
    margin: theme.spacing(1),
    width: "60%",
  },
  buscador: {
    width: "40%",
  },
}));
const BarraTablas = (props) => {
  const classes = useStyles();
  const {
    setopen,
    ocultarAgregar,

    filtro,
    setfiltro,
    funcion,
    buscando,
    setbuscando,
  } = props;

  const { mostrarAlerta } = useContext(AlertaContext);
  // filtros
  // busqueda
  // habilitar Busqueda
  // Modal
  // modal ver
  // const [habilitarBusqueda, sethabilitarBusqueda] = useState(false);

  // const [busqueda, setbusqueda] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (filtro.trim() === "") {
      setbuscando(false);
      return mostrarAlerta("ingrese una busqueda", "error");
    }
    funcion(filtro.trim());
    setbuscando(true);
  };
  return (
    <div>
      {" "}
      <IconBreadcrumbs />
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        pb={3}
        mt={2}
        flexWrap="wrap"
        flexGrow="1"
      >
        <form onSubmit={handleSubmit} className={classes.margin}>
          <TextField
            id=""
            label="Buscar"
            variant="outlined"
            fullWidth
            value={filtro}
            onChange={(e) => setfiltro(e.target.value)}
            InputProps={{
              endAdornment: (
                <Box display="flex" justifyContent="space-between">
                  {" "}
                  {buscando ? (
                    <IconButton
                      aria-label=""
                      color="secondary"
                      onClick={() => {
                        setbuscando(false);
                        setfiltro("");
                      }}
                    >
                      <Backspace color="error" />
                    </IconButton>
                  ) : null}
                  <IconButton aria-label="" color="secondary" type="submit">
                    <FindInPage />
                  </IconButton>
                </Box>
              ),
              labelWidth: "600",
            }}
          />
        </form>

        <Box
          display="flex"
          justifyContent="flex-end"
          flexWrap="wrap"
          flexGrow="1"
        >
          {ocultarAgregar ? null : (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => setopen(true)}
              className={classes.button}
              startIcon={<Add />}
            >
              Añadir nuevo
            </Button>
          )}
        </Box>
      </Box>
      {/* 
      <TablaMascotas
        Elegir={Elegir}
        elegirReceta={elegirReceta}
        ElegirCarnet={ElegirCarnet}
      /> */}
    </div>
  );
};

export default memo(BarraTablas);
