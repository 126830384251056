import React, { memo, useContext } from "react";

import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Hidden, Box } from "@material-ui/core/";

// @material-ui/icons
import { Menu } from "@material-ui/icons";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";

import styles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";
import { Typography } from "@material-ui/core";
import AuthContext from "../../Context/Auth/AuthContext.js";

// import Cargando from "../Cargando/Cargando";
const useStyles = makeStyles(styles);

const Header = (props) => {
  const classes = useStyles();
  const { drawerOpen } = props;
  const { usuario } = useContext(AuthContext);
  // const rutaActual = props.routes.find((ruta) => {
  //   return ruta.path.split("/")[1] === props.location.pathname.split("/")[2];
  // });

  // const [openimagen, setOpenimagen] = useState(false);
  // if (!empresa) return <Cargando titulo="Cargando empresa." />;

  return (
    <>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.container} color="primary">
          <Hidden smDown implementation="css">
            <Box display="flex" width="40%" alignItems="center">
              {drawerOpen ? null : ""}
            </Box>
          </Hidden>
          <Box p={1} display="flex" alignItems="center" width="100%">
            {" "}
            <Hidden smDown implementation="css">
              <Typography
                variant="h5"
                color="secondary"
                className={classes.paddingAndMargin}
                style={{ fontWeight: "bold", color: "white" }}
              >
                {usuario.agencia.toUpperCase() === "JV" ? "JARDINES DEL VALLE" : null}
                {usuario.agencia.toUpperCase() === "MO" ? "MONTE OLIVO NORTE" : null}
                {usuario.agencia.toUpperCase() === "MS" ? "MONTE OLIVO SUR" : null}
              </Typography>
            </Hidden>
          </Box>
          <div className={classes.flex}></div>
          <Hidden smDown implementation="css">
            <AdminNavbarLinks />
          </Hidden>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>{" "}
        {/* <Box
          width="100%"
          style={{
            backgroundColor: "#29b6f6",
            width: "100%",
            height: ".3rem",
          }}
          bgcolor="secondary"
        ></Box> */}
      </AppBar>
    </>
  );
};
export default memo(Header);
Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};
