import React, { useState, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { NavLink, withRouter } from "react-router-dom";
import { NavigateNext } from "@material-ui/icons";
// eslint-disable-next-line
import routes from "../../routes";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const IconBreadcrumbs = (props) => {
  const classes = useStyles();
  const [rutas] = useState({
    path: props.location.pathname.replace("/SGA", ""),
    layout: "/SGA",
  });

  if (!rutas) {
    return null;
  }
  return (
    <Breadcrumbs
      separator={<NavigateNext fontSize="small" />}
      aria-label="breadcrumb"
    >
      <NavLink color="secondary" className={classes.link} to={rutas.layout}>
        {/* <NavigateNext className={classes.icon} /> */}

        <Typography color="secondary" className={classes.link}>
          Dashboard
        </Typography>
      </NavLink>
      {rutas.path.split("/").map((ruta, index) => {
        if (ruta === "") {
          return null;
        }
        const rutaExiste = [].some((novalido) => {
          return novalido === ruta;
        });
        if (rutaExiste) {
          return (
            <Typography
              key={index}
              color="textPrimary"
              className={classes.link}
            >
              {ruta}
            </Typography>
          );
        }
        return (
          <NavLink
            key={index}
            color="textSecondary"
            className={classes.link}
            to={ruta}
          >
            {/* <NavigateNext className={classes.icon} /> */}
            <Typography color="secondary" className={classes.link}>
              {ruta}
            </Typography>
          </NavLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default withRouter(memo(IconBreadcrumbs));
