// inhumacionActiva
// inhumacionActiva
// inhumacionActiva
//
//
//
// inhumacionActiva
// inhumacionActiva
// inhumacionActiva
// inhumacionActiva
// inhumacionActiva
// inhumacionActiva
// inhumacionActiva
// inhumacionActiva
import { createContext } from "react";

const InhumacionesContext = createContext();

export default InhumacionesContext;
