import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AuthState from "./Context/Auth/AuthState";
import AlertaState from "./Context/Alerta/AlertaState";
import ServciciosState from "./Context/Servicios/ServciciosState";
import InhumacionesState from "./Context/Inhumaciones/InhumacionesState";
import CenizasState from "./Context/Cenizas/CenizasState";
import MisasState from "./Context/Misas/MisasState";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./config/theme";
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthState>
        <AlertaState>
          <ServciciosState>
            <InhumacionesState>
              <MisasState>
                <CenizasState>
                  <App />{" "}
                </CenizasState>{" "}
              </MisasState>{" "}
            </InhumacionesState>
          </ServciciosState>
        </AlertaState>
      </AuthState>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
