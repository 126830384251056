import React, { memo, useContext } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Poppers from "@material-ui/core/Popper";
import {
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
} from "@material-ui/core/";

// core components
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import AuthContext from "../../Context/Auth/AuthContext.js";
import CenizaContext from "../../Context/Cenizas/CenizaContext.js";
import InhumacionesContext from "../../Context/Inhumaciones/InhumacionesContext.js";
import ServiciosContext from "../../Context/Servicios/ServiciosContext.js";
import MisasContext from "../../Context/Misas/MisasContext.js";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = (props) => {
  const classes = useStyles();
  const { cerrarSesion, usuario } = useContext(AuthContext);
  const { cerrarSesionCeniza } = useContext(CenizaContext);
  const { cerrarSesionInhumaciones } = useContext(InhumacionesContext);
  const { cerrarSesionServicios } = useContext(ServiciosContext);
  const { cerrarSesionMisas } = useContext(MisasContext);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
          style={{ color: "white" }}
        >
          {usuario.nombre}
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpenProfile(false)}>
                  <MenuList role="menu" color="primary">
                    <MenuItem
                      onClick={() => {
                        setOpenProfile(false);
                        cerrarSesion();
                        cerrarSesionCeniza();
                        cerrarSesionInhumaciones();
                        cerrarSesionServicios();
                        cerrarSesionMisas();
                        props.history.push("/login");
                      }}
                    >
                      Cerrar Sesión
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
};

export default memo(withRouter(AdminNavbarLinks));
