import React, { useState, useContext, useEffect } from "react";

import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
  FormHelperText,
  FormLabel,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import Paper from "@material-ui/core/Paper";

import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";

import clienteAxios from "../../config/axios";

import {
  Person,
  Visibility,
  VisibilityOff,
  Business,
  Link,
} from "@material-ui/icons";
import logo from "../../assets/img/logo.png";
import fondo from "../../assets/img/fondo-login.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    width: "100%",
    color: theme.palette.text.secondary,
    minHeight: "calc(100vh)",
    backgroundImage: `url(${fondo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundAttachment: "fixed",
  },
  paper: {
    borderRadius: "12px",
    height: "auto",

    color: theme.palette.text.secondary,
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "55%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100vh",
    },
  },
  paperRecuperarPass: {
    textAlign: "center",
    borderRadius: "12px",

    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100vh",
    },
  },
  font: {
    color: "#5ad2f5",
  },
  enlace: {
    color: "#5ad2f5",
    fontSize: "14px",
    "&:hover": {
      color: "#2e3b9b",
    },
  },
  boton: {
    backgroundColor: "#5ad2f5",
    "&:hover": {
      backgroundColor: blue[500],
    },
  },
  or: {
    position: "fixed",
  },
  width: {
    width: "42%",
  },
  botonValidar: {
    backgroundColor: "#2c3a47",
    color: "white",
    "&:hover": {
      boxShadow: "1px 2px 3px solid #0f1d28 ",
      backgroundColor: "#0f1d28",
    },
  },

  altura: {
    borderRadius: "12px",

    color: theme.palette.text.secondary,

    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
    },
  },
}));

export default function Login({ history, location }) {
  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  const { iniciarSesion, mensaje, autenticado } = useContext(AuthContext);
  const [check, setCheck] = useState(false);
  const [recupear, setrecupear] = useState("");
  const [form, setform] = useState({
    usuario: "",
    password: "",
    agencia: "",
  });
  const [verpass, setverpass] = useState(false);
  const [
    // eslint-disable-next-line
    open,
    setopen,
  ] = useState(false);

  const { usuario, password, agencia } = form;
  const [recuperarContraseña, setrecuperarContraseña] = useState(false);
  // const [terminosCondiciones, setterminosCondiciones] = useState(false);
  const classes = useStyles();
  const handleCheck = (event) => {
    setCheck(event.target.checked);
  };
  const login = () => {
    setrecuperarContraseña(false);
  };
  // const recuperar = () => {
  //   setrecuperarContraseña(true);
  // };

  const handleRecuperarContraseña = async () => {
    try {
      const res = await clienteAxios.post("/usuario/recuperar", { recupear });

      mostrarAlerta(res.data.msg, "success");
    } catch (error) {
      mostrarAlerta(error.response.data.msg, "error");
    }
  };

  const botonLogin = () => {
    if (
      usuario.trim() !== "" &&
      password.trim() !== "" &&
      agencia.trim() !== ""
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  // eslint-disable-next-line
  // const handleSubmitregistrarse = () => {

  //   registrarse(registro);
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (botonLogin() === false) {
      return mostrarAlerta("Los campos son obligatorios", "error");
    }

    iniciarSesion({ usuario, password, agencia });
    if (check) {
      localStorage.setItem("usuario", usuario);
      localStorage.setItem("password", password);
      localStorage.setItem("agencia", agencia);
      // localStorage.setItem("codigo", codigo);
      localStorage.setItem("recordar", true);
    } else {
      localStorage.setItem("usuario", "");
      localStorage.setItem("password", "");
      localStorage.setItem("agencia", "");
      localStorage.setItem("recordar", false);
    }
  };

  useEffect(() => {
    if (autenticado) {
      history.push("/monteolivo/Servicios");
    }
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
      setopen(false);
    }
    //eslint-disable-next-line
  }, [mensaje, autenticado]);

  useEffect(() => {
    const recordar = localStorage.getItem("recordar") === "true" ? true : false;

    setCheck(recordar);
    if (recordar) {
      const usuario = localStorage.getItem("usuario");
      const password = localStorage.getItem("password");
      const agencia = localStorage.getItem("agencia");
      setform({
        ...form,
        usuario: usuario ? usuario : "",
        password: password ? password : "",
        agencia: agencia ? agencia : "",
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        alignItems="center"
        height="100vh"
      >
        <Paper
          className={
            recuperarContraseña ? classes.paperRecuperarPass : classes.paper
          }
        >
          <Box display="flex" flexDirection="column" width="100%">
            {alerta ? (
              <Alert severity={alerta.categoria} variant="filled">
                {alerta.msg}{" "}
              </Alert>
            ) : null}

            {recuperarContraseña ? (
              //recupear contraseña
              <Box p={8}>
                <Box
                  mt={4}
                  mb={3}
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  flexDirection="column"
                >
                  <Typography
                    style={{ width: "100%" }}
                    variant="h5"
                    color="primary"
                  >
                    ¿Olvidastes tu contraseña?
                  </Typography>
                  <br />
                  <Typography
                    style={{ width: "100%" }}
                    variant="subtitle2"
                    color="initial"
                  >
                    Escribe tu dirección de correo electrónico o tu usuario para
                    comenzar el proceso de restablecimiento.
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={recupear}
                  id="usuario"
                  label="Correo"
                  name="usuario"
                  autoComplete="usuario"
                  onChange={(e) => setrecupear(e.target.value)}
                />
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-around"
                  mt={2}
                  alignItems="center"
                  style={{ marginLeft: ".5rem", marginRight: ".5rem" }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => login()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => handleRecuperarContraseña()}
                    color="primary"
                    fullWidth
                    style={{ marginLeft: ".5rem", marginRight: ".5rem" }}
                  >
                    Enviar
                  </Button>
                </Box>
              </Box>
            ) : (
              // LOGIN
              <>
                <Box
                  display="flex"
                  justifyContent="space-betweem"
                  width="100%"
                  className={classes.altura}
                >
                  <Box width="100%" p={3} display="flex" flexDirection="column">
                    {" "}
                    <form
                      className={classes.form}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <Box display="flex" justifyContent="center" mb={3}>
                        <img src={logo} width="20%" alt="" />{" "}
                      </Box>
                      <TextField
                        id="standard-select-agencia"
                        select
                        variant="outlined"
                        style={{ justifySelf: "flex-start" }}
                        label="Empresa"
                        value={agencia}
                        name="agencia"
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <IconButton aria-label="">
                              <Business color="secondary" />
                            </IconButton>
                          ),
                        }}
                      >
                        <MenuItem value="JV">Jardines del Valle</MenuItem>
                        <MenuItem value="MO">Monte Olivo Norte </MenuItem>
                        <MenuItem value="MS">Monte Olivo Sur </MenuItem>
                      </TextField>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="usuario"
                        color="primary"
                        label="Usuario"
                        name="usuario"
                        value={usuario}
                        onChange={handleChange}
                        autoComplete="usuario"
                        InputProps={{
                          endAdornment: (
                            <IconButton aria-label="">
                              <Person color="secondary" />
                            </IconButton>
                          ),
                        }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="usuario"
                        color="primary"
                        label="Contraseña"
                        type={verpass ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              {verpass ? (
                                <IconButton
                                  aria-label=""
                                  onClick={() => setverpass(!verpass)}
                                >
                                  <Visibility color="secondary" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  aria-label=""
                                  onClick={() => setverpass(!verpass)}
                                >
                                  <VisibilityOff color="secondary" />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box
                        width="100% "
                        justifyContent="space-between"
                        display="flex"
                        alignItems="center"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={check}
                              onChange={handleCheck}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Recuerdame"
                        />
                        {/* <Button
                          to="#!"
                          color="secondary"
                          style={{
                            fontSize: ".6rem",
                            padding: ".2rem",
                            margin: ".2rem",
                          }}
                          onClick={() => recuperar()}
                        >
                          ¿Olvidaste tu contraseña ?
                        </Button> */}
                      </Box>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color={botonLogin() ? "primary" : "inherit"}
                      >
                        Ingresar
                      </Button>
                      <br />
                      <br />
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={2}
                      >
                        <FormControl>
                          <FormHelperText>
                            <a href="https//2jl.ec">https//2jl.ec</a> <br />
                            <a href="https//neitor.com"> https//neitor.com</a>
                          </FormHelperText>
                        </FormControl>
                        <FormControl>
                          <FormLabel>Servicios :</FormLabel>
                          <FormHelperText>
                            <IconButton aria-label="">
                              {/* eslint-disable-next-line */}
                              <a
                                href="https://tablero.jadeval.com.ec/servicios"
                                target="_blank"
                              >
                                <Link color="primary" />
                              </a>
                            </IconButton>
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </div>
  );
}
