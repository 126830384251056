import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2abd62",
    },
    secondary: {
      main: "#8ec445",
    },
  },
});

export default theme;
