import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../Context/Auth/AuthContext";

import CenizaContext from "../../Context/Cenizas/CenizaContext";
import MisasContext from "../../Context/Misas/MisasContext";
import InhumacionesContext from "../../Context/Inhumaciones/InhumacionesContext";
import ServiciosContext from "../../Context/Servicios/ServiciosContext";
import Cargando from "../../components/Extra/Cargando";
import {
  Box,
  Card,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  MenuItem,
  TextField,
  IconButton,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import { Business, CalendarToday, Cancel, Today } from "@material-ui/icons";
import { ordenar, fechaEspañol } from "../../config/validaciones";
import clienteAxios from "../../config/axios";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    color: theme.palette.text.secondary,
  },
  scroll: {
    maxHeight: "90vh",
    overflow: "scroll",
  },
  fontSize: {
    fontSize: "1.1rem !important",
    fontWeight: "bold",
    color: "#3450bb",
  },
  backgd: {
    color: "white",
    backgroundColor: "#3350bb",
    fontSize: "1.1rem !important",
    fontWeight: "bold",
  },
  header: {
    fontSize: "1.3rem !important",
    fontWeight: "900",
    padding: "0",
    margin: "0",
    backgroundColor: "#51b951",
    color: "white",
  },
  font500: {
    fontSize: "1.3rem !important",
    fontWeight: "500",
    padding: "0",
    margin: "0",
  },
}));
const JV = () => {
  const [form, setform] = useState({
    agencia: "JV",
    subagencia: "",
  });

  const { agencia, subagencia } = form;
  const participantsRef = React.useRef(agencia);

  const classes = useStyles();
  const { socket } = useContext(AuthContext);
  const [subAgenciasData, setsubAgenciasData] = useState(null);
  const getsubAgenciasData = async (agenciaPost = agencia) => {
    console.log({
      agencia: agenciaPost,
    });
    const res = await clienteAxios.post("/salas", {
      agencia: agenciaPost,
    });
    setsubAgenciasData(res.data);
    console.log({ res });
  };
  useEffect(() => {
    getsubAgenciasData();
  }, []);

  const {
    agregarCeniza,
    editarCeniza,
    eliminarCeniza,
    rows: rowsCenizas,
    obtenerCenizas,
  } = useContext(CenizaContext);
  const {
    agregarMisa,
    editarMisa,
    eliminarMisa,
    obtenerMisas,
    rows: rowsMisas,
  } = useContext(MisasContext);
  const {
    agregarInhumacion,
    editarInhumacion,
    eliminarInhumacion,
    obtenerInhumaciones,
    rows: rowsInhumaciones,
  } = useContext(InhumacionesContext);
  const {
    agregarServicio,
    editarServicio,
    eliminarServicio,
    obtenerServicios,
    rows: rowsServicios,
  } = useContext(ServiciosContext);
  const [JV] = useState(["BR", "MT", "CS", "RC", "CC", "CG", "SR", "AJ"]);
  const [MO] = useState(["MO", "AM", "GV", "AE", "RC", "EA"]);
  const [MS] = useState(["MO", "RC", "JV", "AJ", "GS", "MM"]);
  const [seccion, setseccion] = useState({ hoy: true, todos: false });

  const horas = new Date().setHours(12);
  const [dia] = useState(new Date(horas).toISOString());
  const sumarDias = () => {
    const fechaNew = new Date(dia);

    const parse = fechaNew.setDate(fechaNew.getDate() + 1);

    return new Date(parse).toISOString();
  };
  const misaFind = (hora, hoydia) => {
    const misa = rowsMisas.sort(ordenar("fecha")).find((element) => {
      const row = JSON.parse(element.tabInformacion);

      const rowdia = row.fecha.split("T")[0];
      const dia = hoydia.split("T")[0];

      const rowhora = row.fecha.split("T")[1];

      return Number(rowhora.substr(0, 2)) === hora && dia === rowdia;
    });

    if (!misa) {
      return null;
    }
    const row = JSON.parse(misa.tabInformacion);
    return (
      <Box>
        <Box pb={0}>
          <Typography variant="subtitle2" color="textPrimary" align="center">
            {/* {row.nombres} */}
          </Typography>

          <Box>
            {" "}
            <Typography
              variant="body2"
              className={classes.font500}
              color="textPrimary"
            >
              {row.nombres}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  const nombres_dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  function MostrarFecha(mañana) {
    if (mañana) {
      const fecha_actual = new Date().setDate(new Date().getDate() + 1);

      const dia_semana = new Date(fecha_actual).getDay(); //dia de la semana

      return nombres_dias[dia_semana] + " " + new Date(fecha_actual).getDate();
    } else {
      const fecha_actual = new Date();

      const dia_semana = fecha_actual.getDay(); //dia de la semana

      //escribe en pagina

      return nombres_dias[dia_semana] + " " + new Date().getDate();
    }
  }

  const ValidarDia = (fechaItem) => {
    const hoyDia = new Date().getDate();
    const mesActual = new Date().getMonth();
    const añoActual = new Date().getFullYear();
    const diaItem = new Date(fechaItem).getDate();
    const mesItem = new Date(fechaItem).getMonth();
    const añoItem = new Date(fechaItem).getFullYear();
    if (fechaItem === "") {
      return true;
    }
    if (hoyDia <= diaItem && mesActual <= mesItem && añoActual <= añoItem) {
      return true;
    }
  };
  useEffect(() => {
    if (socket) {
      socket.on("guardadoExitoso", async (data) => {
        await agencia;
        await participantsRef;
        const row = JSON.parse(data);

        if (row.tabAgencia !== participantsRef.current) {
          return;
        }
        if (row.tabTipo === "cenizas") {
          agregarCeniza(row);
        }
        if (row.tabTipo === "misas") {
          agregarMisa(row);
        }
        if (row.tabTipo === "inhumaciones") {
          agregarInhumacion(row);
        }
        if (row.tabTipo === "servicios") {
          agregarServicio(row);
        }

        // setmessages((messages) => [...messages, message]);
      });
      socket.on("actualizadoExitoso", async (data) => {
        await agencia;
        await participantsRef;
        const row = JSON.parse(data);

        if (row.tabAgencia !== participantsRef.current) {
          return;
        }
        if (row.tabTipo === "cenizas") {
          editarCeniza(row);
        }
        if (row.tabTipo === "misas") {
          editarMisa(row);
        }
        if (row.tabTipo === "inhumaciones") {
          editarInhumacion(row);
        }
        if (row.tabTipo === "servicios") {
          editarServicio(row);
        }

        // setmessages((messages) => [...messages, message]);
      });

      socket.on("eliminadoExitoso", (data) => {
        if (data.tipo === "cenizas") {
          eliminarCeniza(data.id);
        }
        if (data.tipo === "misas") {
          eliminarMisa(data.id);
        }
        if (data.tipo === "inhumaciones") {
          eliminarInhumacion(data.id);
        }
        if (data.tipo === "servicios") {
          eliminarServicio(data.id);
        }
      });
    } //eslint-disable-next-line
  }, []);

  //obteniendo datos
  useEffect(() => {
    if (!rowsCenizas) {
      obtenerCenizas("JV");
    }
    if (!rowsInhumaciones) {
      obtenerInhumaciones("JV");
    }
    if (!rowsMisas) {
      obtenerMisas("JV");
    }
    if (!rowsServicios) {
      obtenerServicios("JV");
    }
    // eslint-disable-next-line
  }, []);

  if (!rowsServicios) {
    return <Cargando titulo="Cargando Servicios" />;
  }

  if (!rowsMisas) {
    return <Cargando titulo="Cargando Misas" />;
  }

  if (!rowsInhumaciones) {
    return <Cargando titulo="Cargando asignaturas" />;
  }
  if (!rowsCenizas) {
    return <Cargando titulo="Cargando Cenizas" />;
  }
  if (!subAgenciasData) {
    return <Cargando titulo="Cargando Cenizas" />;
  }

  if (!socket) {
    return null;
  }
  return (
    <div className={classes.root}>
      {" "}
      <Grid container spacing={1}>
        <Grid item lg={3} md={12} xs={12}></Grid>
        <Grid item lg={3} md={6} xs={12}>
          <TextField
            id="standard-select-agencia"
            select
            variant="outlined"
            style={{ justifySelf: "flex-start", marginTop: ".5rem" }}
            label="Agencia"
            value={agencia}
            name="agencia"
            defaultValue="JV"
            onChange={(e) => {
              participantsRef.current = e.target.value;
              getsubAgenciasData(e.target.value);
              setform({
                ...form,
                [e.target.name]: e.target.value,
                subagencia: "",
              });
              obtenerCenizas(e.target.value);
              obtenerInhumaciones(e.target.value);
              obtenerMisas(e.target.value);
              obtenerServicios(e.target.value);
            }}
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              displayEmpty: true,
            }}
            InputProps={{
              endAdornment: (
                <IconButton aria-label="">
                  <Business color="inherit" />
                </IconButton>
              ),
            }}
          >
            <MenuItem value="JV">Jardines del Valle</MenuItem>
            <MenuItem value="MO">Monte Olivo Norte </MenuItem>
            <MenuItem value="MS">Monte Olivo Sur </MenuItem>
          </TextField>
        </Grid>{" "}
        <Grid item lg={3} md={6} xs={12}>
          <TextField
            id="standard-select-agencia"
            select
            variant="outlined"
            style={{ justifySelf: "flex-start", marginTop: ".5rem" }}
            label="SubAgencia"
            value={subagencia}
            name="subagencia"
            onChange={(e) => {
              setform({
                ...form,
                [e.target.name]: e.target.value,
              });
              console.log(e.target.value);
              obtenerCenizas(agencia, e.target.value);
              obtenerInhumaciones(agencia, e.target.value);
              obtenerMisas(agencia, e.target.value);
              obtenerServicios(agencia, e.target.value);
            }}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label=""
                  onClick={(e) => {
                    obtenerCenizas(agencia);
                    obtenerInhumaciones(agencia);
                    obtenerMisas(agencia);
                    obtenerServicios(agencia);
                    setform({
                      ...form,
                      subagencia: "",
                    });
                  }}
                >
                  <Cancel color="error" />
                </IconButton>
              ),
            }}
          >
            {subAgenciasData.map(({ codsubagenci, descrip }) => (
              <MenuItem value={codsubagenci}>{descrip}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={0} xs={12}></Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography
              variant="subtitle1"
              className={classes.backgd}
              color="primary"
              align="center"
            >
              Servicios
            </Typography>
            <Divider />
            <Box
              display="flex"
              overflow="scroll"
              maxHeight="85vh"
              flexWrap="wrap"
            >
              {rowsServicios.sort(ordenar("misa")).map((element) => {
                const row = JSON.parse(element.tabInformacion);
                if (row.estado !== "ACTIVO") return null;
                if (!ValidarDia(row.misa)) {
                  return null;
                }
                return (
                  <Box mt={1} minWidth="50%" maxWidth="50%">
                    <Card variant="outlined">
                      <Box>
                        <Typography
                          style={{ backgroundColor: "#6D712E" }}
                          variant="subtitle2"
                          color="textPrimary"
                          align="center"
                          className={classes.header}
                        >
                          {row.nombres}
                        </Typography>
                        <Divider />
                        <Box p={1} pb={0}>
                          {" "}
                          <Typography
                            variant="body2"
                            className={classes.font500}
                            color="textPrimary"
                          >
                            <Typography
                              variant="body1"
                              className={classes.fontSize}
                              color="primary"
                              component="span"
                            >
                              Serv:{" "}
                            </Typography>
                            {row.servicio}
                          </Typography>{" "}
                          <Typography
                            variant="body2"
                            className={classes.font500}
                            color="textPrimary"
                          >
                            <Typography
                              variant="body1"
                              className={classes.fontSize}
                              color="primary"
                              component="span"
                            >
                              Sal:{" "}
                            </Typography>
                            {row.sala}
                          </Typography>
                          <>
                            {" "}
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              style={{
                                color: row.misa === "" ? "#D26161" : "",
                              }}
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="error"
                                component="span"
                              >
                                Ms:{" "}
                              </Typography>
                              {row.misa === ""
                                ? "SIN CONFIRMAR"
                                : fechaEspañol(row.misa)}
                            </Typography>
                          </>
                          <Typography
                            variant="body2"
                            className={classes.font500}
                            color="textPrimary"
                          >
                            <Typography
                              variant="body1"
                              className={classes.fontSize}
                              color="primary"
                              component="span"
                            >
                              Ub:{" "}
                            </Typography>
                            {row.ubicacion}
                          </Typography>{" "}
                          <Typography
                            variant="body2"
                            className={classes.font500}
                            color="textPrimary"
                          >
                            <Typography
                              variant="body1"
                              className={classes.fontSize}
                              color="primary"
                              component="span"
                            >
                              Ase:
                            </Typography>
                            {row.asesor}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.font500}
                            color="textPrimary"
                          >
                            <Typography
                              variant="body1"
                              className={classes.fontSize}
                              color="primary"
                              component="span"
                            >
                              Obs:
                            </Typography>
                            {row.observacion}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                );
              })}
            </Box>
          </Paper>
        </Grid>
        {/* cenizas e inhumaciones */}
        <Grid item lg={3} md={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle1" color="primary" align="center">
              <Box
                display="flex"
                justifyContent="space-around"
                className={classes.backgd}
              >
                <Typography
                  variant="subtitle1"
                  color="initial"
                  className={classes.backgd}
                  component="span"
                >
                  Cenizas
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.backgd}
                  color="primary"
                  align="center"
                  component="span"
                >
                  Inhumaciones
                </Typography>
              </Box>
            </Typography>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              flexgrow={1}
            >
              <Box
                display="flex"
                flexDirection="column"
                overflow="scroll"
                maxHeight="85vh"
                flexgrow={1}
                maxWidth="49%"
              >
                {rowsCenizas.sort(ordenar("fecha")).map((element) => {
                  const row = JSON.parse(element.tabInformacion);
                  if (row.estado !== "ACTIVO") return null;
                  if (!ValidarDia(row.fecha)) {
                    return null;
                  }
                  return (
                    <Box mt={1}>
                      <Card variant="outlined">
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            align="center"
                            className={classes.header}
                            style={{ backgroundColor: "#D3D3D3" }}
                          >
                            {row.nombres}
                          </Typography>
                          <Divider />
                          <Box p={1} pb={0}>
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                              style={{
                                color: row.fecha === "" ? "#D26161" : "",
                              }}
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Fec:{" "}
                              </Typography>
                              {row.fecha === ""
                                ? "SIN CONFIRMAR"
                                : fechaEspañol(row.fecha)}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Ub:{" "}
                              </Typography>
                              {row.ubicacion}
                            </Typography>{" "}
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Ase:{" "}
                              </Typography>
                              {row.asesor}
                            </Typography>{" "}
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Obs:
                              </Typography>
                              {row.observacion}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  );
                })}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                overflow="scroll"
                maxHeight="85vh"
                maxWidth="49%"
                flexgrow={1}
              >
                {rowsInhumaciones.sort(ordenar("fecha")).map((element) => {
                  const row = JSON.parse(element.tabInformacion);
                  if (row.estado !== "ACTIVO") return null;
                  if (!ValidarDia(row.fecha)) {
                    return null;
                  }
                  return (
                    <Box mt={1}>
                      <Card variant="outlined">
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            align="center"
                            className={classes.header}
                            style={{ backgroundColor: "#013220" }}
                          >
                            {row.nombres}
                          </Typography>
                          <Divider />
                          <Box p={1} pb={0}>
                            {" "}
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                              style={{
                                color: row.fecha === "" ? "#D26161" : "",
                              }}
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Fec:{" "}
                              </Typography>
                              {row.fecha === ""
                                ? "SIN CONFIRMAR"
                                : fechaEspañol(row.fecha)}
                            </Typography>{" "}
                            {/* <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Serv:
                              </Typography>
                              {row.servicio}
                            </Typography>{" "} */}
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Ub:{" "}
                              </Typography>
                              {row.ubicacion}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Ase:{" "}
                              </Typography>
                              {row.asesor}
                            </Typography>{" "}
                            <Typography
                              variant="body2"
                              className={classes.font500}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Obs:
                              </Typography>
                              {row.observacion}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.backgd}
            >
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setseccion({ hoy: true })}
              >
                <Today color={seccion.hoy ? "secondary" : "inherit"} />
              </IconButton>
              <Typography
                variant="subtitle1"
                color="initial"
                component="span"
                className={classes.backgd}
              >
                MISAS
              </Typography>
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setseccion({ todos: true })}
              >
                <CalendarToday color={seccion.todos ? "secondary" : "white"} />
              </IconButton>
            </Box>
            <Divider />
            {seccion.hoy ? (
              <Box display="flex" overflow="scroll" maxHeight="85vh">
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.header}
                          style={{
                            maxWidth: "50px !important",
                          }}
                        >
                          Hora
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.header}
                        >
                          {MostrarFecha()}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.header}
                        >
                          {MostrarFecha(true)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                        { hora: 8, label: "8:00" },
                        { hora: 9, label: "9:00" },
                        { hora: 10, label: "10:00" },
                        { hora: 11, label: "11:00" },
                        { hora: 12, label: "12:00" },
                        { hora: 13, label: "13:00" },
                        { hora: 14, label: "14:00" },
                        { hora: 15, label: "15:00" },
                        { hora: 16, label: "16:00" },
                        { hora: 17, label: "17:00" },
                        { hora: 18, label: "18:00" },
                        { hora: 19, label: "19:00" },
                        { hora: 20, label: "20:00" },
                        { hora: 21, label: "21:00" },
                        { hora: 22, label: "22:00" },
                        { hora: 23, label: "23:00" },
                      ].map((row) => (
                        <TableRow key={row}>
                          <TableCell
                            size="small"
                            align="center"
                            className={classes.font500}
                            style={{
                              maxWidth: "50px !important",
                              borderRight: ".3rem solid #ccc",
                              borderBottom: ".3rem solid #ccc",
                            }}
                          >
                            {row.label}
                          </TableCell>

                          <TableCell
                            padding="none"
                            align="center"
                            style={{
                              borderRight: ".3rem solid #ccc",
                              borderBottom: ".3rem solid #ccc",
                            }}
                          >
                            {misaFind(row.hora, dia)}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="center"
                            style={{ borderBottom: ".3rem solid #ccc" }}
                          >
                            {misaFind(row.hora, sumarDias())}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : null}
            {seccion.todos ? (
              <Box
                display="flex"
                flexDirection="column"
                overflow="scroll"
                maxHeight="85vh"
              >
                {rowsMisas.sort(ordenar("fecha")).map((element) => {
                  const row = JSON.parse(element.tabInformacion);
                  if (row.estado !== "ACTIVO") return null;
                  if (!ValidarDia(row.fecha)) {
                    return null;
                  }
                  return (
                    <Box mt={1}>
                      <Card variant="outlined">
                        <Box p={1} pb={0}>
                          <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            align="center"
                            className={classes.fontSize}
                          >
                            {row.nombres}
                          </Typography>
                          <Divider />
                          <Box>
                            <Typography
                              variant="body2"
                              className={classes.fontSize}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Modalidad:{" "}
                              </Typography>
                              {row.modalidad.join(" - ")}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.fontSize}
                              color="textPrimary"
                            >
                              <Typography
                                variant="body1"
                                className={classes.fontSize}
                                color="primary"
                                component="span"
                              >
                                Fecha:
                              </Typography>
                              {fechaEspañol(row.fecha)}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default JV;
