/*eslint-disable*/
import React, { useState, memo } from "react";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { Message, Mail } from "@material-ui/icons";
import { IconButton, Badge, Typography, Button, Box } from "@material-ui/core";
import Soporte from "../Extra/Soporte";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);
const Footer = (props) => {
  const [open, setopen] = useState(false);
  const classes = useStyles();
  return (
    <Box
      display="flex"
      mb={1}
      justifyContent="space-between"
      alignItems="center"
      p={1}
    >
      <Typography variant="subtitle1" color="initial">
        &copy; {1900 + new Date().getYear()} Tablero Monteolivo{" "}
      </Typography>

      {/* <Soporte setopen={setopen} open={open} /> */}
    </Box>
  );
};

export default memo(withRouter(Footer));
