// types

import {
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  OBTENER_USUARIO,
  CERRAR_SESION,
  REGISTRO_ERROR,
  REGISTRO_EXITOSO,
  OBTENER_SALAS_COMBO,
  OBTENER_SERVICIOS_COMBO,
  OBTENER_ASESORES_COMBO,
  SOCKET,
} from "../../types/index";
import io from "socket.io-client";
const reducer = (state, action) => {
  switch (action.type) {
    case OBTENER_SALAS_COMBO:
      return {
        ...state,
        salas: action.payload,
      };
    case OBTENER_SERVICIOS_COMBO:
      return {
        ...state,
        servicios: action.payload,
      };
    case LOGIN_EXITOSO:
    case REGISTRO_EXITOSO:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        cargando: false,
        usuario: action.payload,
        mensaje: null,
        autenticado: true,
      };
    case OBTENER_USUARIO:
      //real 6000ef43fc0e9721cc719c77
      //prueba 5fff54ad20dfce219c151957

      return {
        ...state,
        usuario: action.payload,
        autenticado: true,
      };

    case LOGIN_ERROR:
    case CERRAR_SESION:
    case REGISTRO_ERROR:
      localStorage.removeItem("token");

      state.socket.disconnect("disconnect", (mensaje) => {});
      return {
        ...state,
        token: null,
        mensaje: action.payload,
        usuario: null,
        autenticado: false,
        cargando: false,
        salas: null,
        servicios: null,
        socket: io("https://backtableromonteolivo.neitor.com/"),
      };
    case SOCKET:
      return {
        ...state,
        socket: action.payload,
      };

    case OBTENER_ASESORES_COMBO:
      return {
        ...state,
        asesores: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
