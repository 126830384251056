export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const CERRAR_SESION = "CERRAR_SESION";
export const REGISTRO_ERROR = "REGISTRO_ERROR";
export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";

export const ELIMINAR_MENSAJE = "ELIMINAR_MENSAJE";
export const ERROR_MENSAJE = "ERROR_MENSAJE";
export const OBTENER_SERVICIOS_COMBO = "OBTENER_SERVICIOS_COMBO";
export const OBTENER_SALAS_COMBO = "OBTENER_SALAS_COMBO";
export const OBTENER_ASESORES_COMBO = "OBTENER_ASESORES_COMBO";
export const SOCKET = "SOCKET";
//ALERTAS
export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";

//SERVICIOS
export const OBTENER_SERVICIOS = "OBTENER_SERVICIOS";
export const AGREGAR_SERVICIO = "AGREGAR_SERVICIO";
export const EDITAR_SERVICIO = "EDITAR_SERVICIO";
export const ELIMINAR_SERVICIO = "ELIMINAR_SERVICIO";
export const CHANGE_SERVICIOS_ACTIVO = "CHANGE_SERVICIOS_ACTIVO";

//INHUMACIONES
export const OBTENER_INHUMACIONES = "OBTENER_INHUMACIONES";
export const AGREGAR_INHUMACION = "AGREGAR_INHUMACION";
export const EDITAR_INHUMACION = "EDITAR_INHUMACION";
export const ELIMINAR_INHUMACION = "ELIMINAR_INHUMACION";
export const CHANGE_INHUMACION_ACTIVA = "CHANGE_INHUMACION_ACTIVA";
//HORARIOS
export const OBTENER_MISAS = "OBTENER_MISAS";
export const AGREGAR_MISA = "AGREGAR_MISA";
export const EDITAR_MISA = "EDITAR_MISA";
export const ELIMINAR_MISA = "ELIMINAR_MISA";
export const CHANGE_MISA_ACTIVO = "CHANGE_MISA_ACTIVO";
//CENIZAS
export const OBTENER_CENIZAS = "OBTENER_CENIZAS";
export const AGREGAR_CENIZA = "AGREGAR_CENIZA";
export const EDITAR_CENIZA = "EDITAR_CENIZA";
export const ELIMINAR_CENIZA = "ELIMINAR_CENIZA";
export const CHANGE_CENIZA_ACTIVO = "CHANGE_CENIZA_ACTIVO";
