import {
  OBTENER_INHUMACIONES,
  AGREGAR_INHUMACION,
  EDITAR_INHUMACION,
  ELIMINAR_INHUMACION,
  CHANGE_INHUMACION_ACTIVA,
  CERRAR_SESION,
} from "../../types/index";

const reducer = (state, action) => {
  switch (action.type) {
    case OBTENER_INHUMACIONES:
      return {
        ...state,
        rows: action.payload,
      };
    case AGREGAR_INHUMACION:
      const newRows = [...state.rows, action.payload];

      return {
        ...state,
        rows: newRows,
      };
    case EDITAR_INHUMACION:
      return {
        ...state,
        rows: state.rows.map((row) => {
          if (row.tabId === action.payload.tabId) {
            return action.payload;
          } else {
            return row;
          }
        }),
      };
    case ELIMINAR_INHUMACION:
      return {
        ...state,
        rows: state.rows.filter((row) => row.tabId !== action.payload),
      };

    case CHANGE_INHUMACION_ACTIVA:
      return {
        ...state,
        inhumacionActiva: action.payload,
      };
    case CERRAR_SESION:
      return {
        ...state,
        rows: null,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
