import React, { useReducer, memo } from "react";
import {
  OBTENER_USUARIO,
  LOGIN_ERROR,
  LOGIN_EXITOSO,
  CERRAR_SESION,
  REGISTRO_EXITOSO,
  ELIMINAR_MENSAJE,
  OBTENER_SERVICIOS_COMBO,
  OBTENER_SALAS_COMBO,
  OBTENER_ASESORES_COMBO,
} from "../../types/index";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import io from "socket.io-client";
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";
const AuthState = (props) => {
  const initialState = {
    salas: null,
    servicios: null,
    asesores: null,
    token: localStorage.getItem("token"),
    autenticado: false,
    usuario: null,
    mensaje: null,
    cargando: true,
    socket: io("https://backtableromonteolivo.neitor.com/"),
  };
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  //funciones
  const registrarse = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/auth/crear", datos);

      dispatch({
        type: REGISTRO_EXITOSO,
        payload: respuesta.data.token,
      });

      // usuarioAutenticado();
      eliminarMensaje();
    } catch (error) {
      const alerta = {
        msg: error.response.data.mensaje,
        categoria: "error",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
      eliminarMensaje();
    }
  };

  const obtenerSalas = async (agencia, subagencia) => {
    console.log({ agencia, subagencia });
    const res = await clienteAxios.post("/salas", {
      agencia,
      subagencia,
    });

   
    dispatch({
      type: OBTENER_SALAS_COMBO,
      payload: res.data,
    });
  };
  const obtenerServicios = async (agencia) => {
 
    const res = await clienteAxios.post("/servicios", {
      agencia,
    });

    dispatch({
      type: OBTENER_SERVICIOS_COMBO,
      payload: res.data,
    });
  };
  const obtenerAsesores = async (agencia) => {
    const res = await clienteAxios.post("/asesores", {
      agencia,
    });

    dispatch({
      type: OBTENER_ASESORES_COMBO,
      payload: res.data,
    });
  };

  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/auth/login", datos);

      dispatch({
        type: LOGIN_EXITOSO,
        payload: respuesta.data,
      });
      obtenerSalas(respuesta.data.agencia, respuesta.data.subAgencia);
      obtenerServicios(respuesta.data.agencia);
      obtenerAsesores(respuesta.data.agencia);
      // usuarioAutenticado();
      eliminarMensaje();
    } catch (error) {
      const alerta = {
        msg: error.response.data.msg,
        categoria: "error",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
      eliminarMensaje();
    }
  };
  const usuarioAutenticado = async () => {
    if (state.usuario) {
      return;
    }
    const token = localStorage.getItem("token");
    if (token) {
      //funciion  que coloca el token por headers
      tokenAuth(token);
    }

    try {
      const respuesta = await clienteAxios.get("/auth");

      obtenerSalas(respuesta.data.agencia, respuesta.data.subAgencia);
      obtenerServicios(respuesta.data.agencia);
      obtenerAsesores(respuesta.data.agencia);
      // usuarioAutenticado();
      eliminarMensaje();
      dispatch({
        type: OBTENER_USUARIO,
        payload: respuesta.data,
      });
      eliminarMensaje();
    } catch (error) {
      const alerta = {
        msg: error.response.data.msg,
        categoria: "error",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
      eliminarMensaje();
    }
  };

  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  const eliminarMensaje = async () => {
    setTimeout(() => {
      dispatch({
        type: ELIMINAR_MENSAJE,
      });
    }, 4000);
  };

  return (
    <AuthContext.Provider
      value={{
        //state
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        cargando: state.cargando,
        salas: state.salas,
        servicios: state.servicios,
        asesores: state.asesores,
        socket: state.socket,
        //funciones
        iniciarSesion,
        registrarse,
        usuarioAutenticado,
        cerrarSesion,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default memo(AuthState);
