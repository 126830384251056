import React, { useReducer, memo } from "react";
import clienteAxios from "../../config/axios";
import {
  OBTENER_MISAS,
  AGREGAR_MISA,
  EDITAR_MISA,
  ELIMINAR_MISA,
  CHANGE_MISA_ACTIVO,
  CERRAR_SESION,
} from "../../types/index";

//context
import MisasContext from "./MisasContext";
//reducer
import MisasReducer from "./MisasReducer";
const MisasState = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "agencia",
        label: "agencia",
        minWidth: 150,
      },
      {
        id: "cedula",
        label: "cedula",
        minWidth: 150,
      },
      {
        id: "nombres",
        label: "nombres",
        minWidth: 150,
      },
      {
        id: "fecha",
        label: "fecha",
        minWidth: 150,
      },
      {
        id: "modalidad",
        label: "modalidad",
        minWidth: 150,
      },
      {
        id: "observacion",
        label: "observacion",
        minWidth: 150,
      },
    ],
    misaActiva: {
      tipo: "misas",
      agencia: "MO",
      informacion: {
        cedula: "",
        nombres: "",
        modalidad: [],
        estado: "ACTIVO",
        fecha: "",
        user: "",
        observacion: "",
      },
    },
  };

  const [state, dispatch] = useReducer(MisasReducer, initialState);

  const obtenerMisas = async (agencia, subagencia = "") => {
    console.log({
      tipo: "misas",
      agencia,
      subagencia,
    });
    const res = await clienteAxios.post("/tablero", {
      tipo: "misas",
      agencia,
      subagencia,
    });
    [...res.data].forEach((item) => {
      try {
        JSON.parse(item.tabInformacion);
      } catch (error) {}
    });
    dispatch({
      type: OBTENER_MISAS,
      payload: res.data,
    });
  };

  const agregarMisa = async (misa) => {
    try {
      dispatch({
        type: AGREGAR_MISA,
        payload: misa,
      });
    } catch (error) {}
  };

  const editarMisa = async (misaEditada) => {
    try {
      dispatch({
        type: EDITAR_MISA,
        payload: misaEditada,
      });
    } catch (error) {}
  };

  const eliminarMisa = async (id) => {
    try {
      dispatch({
        type: ELIMINAR_MISA,
        payload: id,
      });
    } catch (error) {}
  };

  const changeMisaActiva = (asignatura) => {
    dispatch({
      type: CHANGE_MISA_ACTIVO,
      payload: asignatura,
    });
  };
  const cerrarSesionMisas = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };
  return (
    <MisasContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        misaActiva: state.misaActiva,
        // funciones
        obtenerMisas,
        agregarMisa,
        editarMisa,
        eliminarMisa,
        changeMisaActiva,
        cerrarSesionMisas,
      }}
    >
      {props.children}
    </MisasContext.Provider>
  );
};

export default memo(MisasState);
