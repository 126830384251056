import {
  OBTENER_MISAS,
  AGREGAR_MISA,
  EDITAR_MISA,
  ELIMINAR_MISA,
  CHANGE_MISA_ACTIVO,
  CERRAR_SESION,
} from "../../types/index";

const reducer = (state, action) => {
  switch (action.type) {
    case OBTENER_MISAS:
      return {
        ...state,
        rows: action.payload,
      };
    case AGREGAR_MISA:
      const newRows = [...state.rows, action.payload];

      return {
        ...state,
        rows: newRows,
      };
    case EDITAR_MISA:
      return {
        ...state,
        rows: state.rows.map((row) => {
          if (row.tabId === action.payload.tabId) {
            return action.payload;
          } else {
            return row;
          }
        }),
      };

    case ELIMINAR_MISA:
      return {
        ...state,
        rows: state.rows.filter((row) => row.tabId !== action.payload),
      };

    case CHANGE_MISA_ACTIVO:
      return {
        ...state,
        misaActiva: action.payload,
      };
    case CERRAR_SESION:
      return {
        ...state,
        rows: null,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
