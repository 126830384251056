//dependencias
import React, { memo } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
//
import "./assets/css/material-dashboard-react.css?v=1.9.0";

// core components
import Admin from "./layouts/Admin";

//coponentes /vistas fuera del admin
import Login from "./views/Auth/Login";
import JV from "./views/Datos/JV";

//state global

import tokenAuth from "./config/token";

//ruta protegida
import RutaPrivada from "./components/rutas/RutaPrivada";

//revisar si hay un  token
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

function App() {
  const hist = createBrowserHistory();

  return (
    <Router history={hist}>
      <Switch>
        <Route path="/login" component={Login} />

        <Route path="/servicios" component={JV} />
        {/* <Route path="/MO" component={MO} />
                      <Route path="/MS" component={MS} /> */}

        <RutaPrivada path="/monteolivo" component={Admin} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  );
}

export default memo(App);
