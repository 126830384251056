import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

// import { secondary } from "../../config/const";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const Dashboard = () => {
  // eslint-disable-next-line
  const classes = useStyles();
  return (
    <div>
      {" "}
      {/* <IconButton>
        <todos.AccessAlarmOutlined />
      </IconButton> */}
      <Grid container spacing={3}></Grid>
    </div>
  );
};

export default Dashboard;
