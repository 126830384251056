import {
  Fireplace,
  WatchLaterTwoTone,
  AccessibilityNew,
  RoomService,
} from "@material-ui/icons";

//Dashboard
import Dashboard from "./views/Dashboard/Dashboard";
import Cenizas from "./views/Sidebar/Cenizas";
import Misas from "./views/Sidebar/Misas";
import Inhumaciones from "./views/Sidebar/Inhumaciones";
import Servicios from "./views/Sidebar/Servicios";

const dashboardRoutes = [
  //DASHBOARD

  {
    path: "/Servicios",
    name: "Servicios",
    icon: RoomService,
    component: Servicios,
    layout: "/monteolivo",
    tipo: "main",
  },
  {
    path: "/Inhumaciones",
    name: "Inhumaciones",
    icon: AccessibilityNew,
    component: Inhumaciones,
    layout: "/monteolivo",
    tipo: "main",
  },

  {
    path: "/Cenizas",
    name: "Cenizas",
    icon: Fireplace,
    component: Cenizas,
    layout: "/monteolivo",
    tipo: "main",
  },
  {
    path: "/Misas",
    name: "Misas",
    icon: WatchLaterTwoTone,
    component: Misas,
    layout: "/monteolivo",
    tipo: "main",
  },
];
export const componentes = {
  Dashboard,
};
export default dashboardRoutes;
