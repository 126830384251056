import {
  OBTENER_CENIZAS,
  AGREGAR_CENIZA,
  EDITAR_CENIZA,
  ELIMINAR_CENIZA,
  CHANGE_CENIZA_ACTIVO,
  CERRAR_SESION,
} from "../../types/index";

const reducer = (state, action) => {
  switch (action.type) {
    case OBTENER_CENIZAS:
      return {
        ...state,
        rows: action.payload,
      };
    case AGREGAR_CENIZA:
      const newRows = [...state.rows, action.payload];
      return {
        ...state,
        rows: newRows,
      };
    case EDITAR_CENIZA:
      return {
        ...state,
        rows: state.rows.map((row) => {
          if (row.tabId === action.payload.tabId) {
            return action.payload;
          } else {
            return row;
          }
        }),
      };

    case ELIMINAR_CENIZA:
      return {
        ...state,
        rows: state.rows.filter((row) => row.tabId !== action.payload),
      };

    case CHANGE_CENIZA_ACTIVO:
      return {
        ...state,
        cenizaActiva: action.payload,
      };
    case CERRAR_SESION:
      return {
        ...state,
        rows: null,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
