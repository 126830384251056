import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, IconButton } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

export default function ConfirmacionDialog(props) {
  const { open, setopen, titulo, contenido, _id, funcion } = props;

  const handleClose = () => {
    setopen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box >
        {" "}
        <Box display="flex" justifyContent="space-between">
          <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
          <IconButton aria-label="" onClick={handleClose} color="secondary">
            <Cancel />
          </IconButton>
        </Box>
        <Box pr={1} pl={1} pb={1}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {contenido}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                funcion(_id);
                handleClose();
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Eliminar
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}
