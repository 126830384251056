export const secondary = "#8bc34a";
export const formato = "(AAAA-MM-DDTHH:MM)";
export const replaceCaracteres = (string) => {
  try {
    let res = string.replace(/(\r\n|\n|\r)/gm, " ");
    res = res.replace(/(["]+)/gm, "");
    res = res.replace(/([']+)/gm, "");
    return res;
  } catch (error) {}
};

export const trimPropsItem = (item) => {
  const json = item;
  Object.entries(item).forEach((itemProps) => {
    if (typeof itemProps[1] === "string") {
      const trimString = itemProps[1].trim();
      json[itemProps[0]] = replaceCaracteres(trimString);
    }
  });

  return json;
};
