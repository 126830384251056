import React, { useState, useContext, useEffect, memo } from "react";
import {
  Modal,
  makeStyles,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  FormControl,
} from "@material-ui/core";

import Draggable from "react-draggable";

import { Cancel } from "@material-ui/icons";
// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../Context/Auth/AuthContext";

import CenizaContext from "../../../Context/Cenizas/CenizaContext";
import { Autocomplete } from "@material-ui/lab";
import { formato, trimPropsItem } from "../../../config/const";
import { isIsoDate } from "../../../config/validaciones";

function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",

    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 750,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "8vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  formD: {
    padding: 0,
    flexGrow: 1,
    margin: theme.spacing(0.5),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
}));

const ModalAsignatura = (props) => {
  const {
    cenizaActiva,
    changeCenizaActiva, // eslint-disable-next-line
  } = useContext(CenizaContext);

  const { usuario, socket, asesores } = useContext(AuthContext);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  //props
  const { open, setopen, seteditar, editar } = props;

  const [ceniza, setceniza] = useState(cenizaActiva);
  const { informacion } = ceniza;

  const defaultActive = () => {
    changeCenizaActiva({
      tipo: "cenizas",
      agencia: "MO",
      informacion: {
        cedula: "",
        nombres: "",
        fecha: "",
        ubicacion: "",
        asesor: "",
        estado: "ACTIVO",
        user: "",
        idAsesor: "",
        observacion: "",
      },
    });
    setopen(false);
    seteditar(false);
  };
  const submit = () => {
    if (
      informacion.cedula.trim() !== "" &&
      informacion.nombres.trim() !== "" &&
      informacion.ubicacion.trim() !== "" &&
      informacion.asesor.trim() !== ""
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    setceniza(cenizaActiva);
  }, [cenizaActiva]);
  const handleChange = (e) => {
    setceniza({
      ...ceniza,
      informacion: {
        ...ceniza.informacion,
        [e.target.name]: e.target.value,
      },
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años
  const [
    ocultar,
    // setocultar
  ] = useState(false);

  // const handleEditar = async () => {
  //   // if (validarEmail(emaildepaypal)) {
  //   //   return mostrarAlerta("Email paypal no es valido ", "error");
  //   // }
  //   if (password !== "") {

  //   setopen(false);

  const handleCrear = async () => {
    if (isIsoDate(informacion.fecha, mostrarAlerta, "Fecha")) {
      return;
    }

    socket.emit("guardarData", {
      ...ceniza,
      informacion: trimPropsItem({
        ...ceniza.informacion,
        user: usuario.usuario,
      }),
      agencia: usuario.agencia,
      subagencia: usuario.subAgencia,
    });
    // agregarAsignatura(ceniza);
    defaultActive();
  };

  const handleEditar = () => {
    if (isIsoDate(informacion.fecha, mostrarAlerta, "Fecha")) {
      return;
    }
    socket.emit("actualizarData", {
      ...ceniza,
      informacion: trimPropsItem({
        ...ceniza.informacion,
        user: usuario.usuario,
      }),
      tipo: ceniza.tabTipo,
      agencia: ceniza.tabAgencia,
      id: ceniza.tabId,
      subagencia: usuario.subAgencia,
    });
    // editarAsignatura(ceniza);
    defaultActive();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        // setocultar(!ocultar);
      }}
    >
      <Draggable handle="#handle">
        <Box display="flex" justifyContent="center">
          <div
            style={ocultar ? modalStyleOculto : modalStyle}
            className={clases.paper}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              style={{ cursor: "-webkit-grab" }}
            >
              <Typography
                id="handle"
                variant="button"
                color="initial"
                align="center"
              >
                {editar ? "Editar" : "Agregar"} ceniza.
              </Typography>
              <Box display="flex" alignItems="center">
                <IconButton
                  aria-label=""
                  style={{ margin: "0px" }}
                  onClick={() => {
                    defaultActive();
                  }}
                >
                  <Cancel color="secondary" />
                </IconButton>
              </Box>
            </Box>

            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Switch
                    name="estado"
                    checked={informacion.estado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setceniza({
                        ...ceniza,
                        informacion: {
                          ...ceniza.informacion,
                          estado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        },
                      });
                    }}
                  />
                }
                label="Estado."
              />
              <Box display={"flex"} >
                <Typography variant="body1" color="primary" >
                  Agencia:{" "}
                  <Typography color="secondary" component={"span"}>
                    {usuario.agencia}
                  </Typography>
                </Typography>
                <Box mx={1}></Box>
                <Typography variant="body1" color="primary">
                  Sub-Agencia:{" "}
                  <Typography color="secondary" component={"span"}>
                    {usuario.subAgencia}
                  </Typography>
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              borderRadius="0px 50px 50px 0px"
              mb={2}
              p={1}
            >
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.formD}
                name="cedula"
                label="Cédula"
                value={informacion.cedula}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.formD}
                name="nombres"
                label="Nombres"
                value={informacion.nombres}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.formD}
                name="fecha"
                label={`Fecha ${formato}`}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                value={informacion.fecha}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.formD}
                name="ubicacion"
                label="Ubicación"
                value={informacion.ubicacion}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <FormControl className={clases.formD} fullWidth>
                <Autocomplete
                  options={asesores.map(
                    (asesor) => asesor.nomase + "/" + asesor.codase
                  )}
                  getOptionLabel={(option) => option.split("/")[0]}
                  id="controlled-demo"
                  value={informacion.asesor}
                  name="a"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setceniza({
                        ...ceniza,
                        informacion: {
                          ...ceniza.informacion,
                          asesor: newValue.split("/")[0],
                          idAsesor: newValue.split("/")[1],
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asesor"
                      size="small"
                      required
                      fullWidth
                      name="a"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
              <TextField
                multiline
                rows={3}
                variant="outlined"
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="observacion"
                label="Observación"
                value={informacion.observacion}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
            </Box>
            {/* <Divider /> */}
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={!submit()}
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Draggable>
    </Modal>
  );
};

export default memo(ModalAsignatura);
// {
// 	"tipo": "inhumaciones",
//    "agencia": "MO",
// 	"informacion": {
// 		"cedula": "1716520000",
//       "nombres": "",
// 		"ceniza": "",
// 		"fecha": "",
// 		"ubicacion": "",
// 		"asesor": "",
// 		"estado": "",
// 		"user": ""
// 	}
// },
