import React, { useState, useContext, useEffect, memo } from "react";
import {
  Modal,
  makeStyles,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
  useTheme,
} from "@material-ui/core";

import Draggable from "react-draggable";

import { Cancel } from "@material-ui/icons";
// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../Context/Auth/AuthContext";

import MisasContext from "../../../Context/Misas/MisasContext";
import { isIsoDate } from "../../../config/validaciones";
import { formato, trimPropsItem } from "../../../config/const";

function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",

    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 750,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "8vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  formD: {
    padding: 0,
    flexGrow: 1,
    margin: theme.spacing(0.5),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
}));

const ModalAsignatura = (props) => {
  const {
    misaActiva,
    changeMisaActiva,
    rows: rowsMisas,
    // eslint-disable-next-line
  } = useContext(MisasContext);

  const { usuario, socket } = useContext(AuthContext);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  //props
  const { open, setopen, seteditar, editar } = props;

  const [misa, setmisa] = useState(misaActiva);
  const { informacion } = misa;

  const defaultActive = () => {
    changeMisaActiva({
      tipo: "misas",
      agencia: "MO",
      informacion: {
        cedula: "",
        nombres: "",
        modalidad: [],
        fecha: "",
        estado: "ACTIVO",
        user: "",
        observacion: "",
      },
    });
    setopen(false);
    seteditar(false);
  };
  const submit = () => {
    if (
      informacion.cedula.trim() !== "" &&
      informacion.nombres.trim() !== "" &&
      informacion.modalidad.length !== 0 &&
      informacion.fecha.trim() !== ""
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    setmisa(misaActiva);
  }, [misaActiva]);
  const handleChange = (e) => {
    setmisa({
      ...misa,
      informacion: {
        ...misa.informacion,
        [e.target.name]: e.target.value,
      },
    });
  };

  //hook de estilos

  const theme = useTheme();
  const clases = useStyles();

  function getStylesEspecialidades(name, especialidad, theme) {
    return {
      fontWeight:
        especialidad.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: especialidad.indexOf(name) === -1 ? "white" : "#8ec445",
    };
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años
  const [
    ocultar,
    // setocultar
  ] = useState(false);

  // const handleEditar = async () => {
  //   // if (validarEmail(emaildepaypal)) {
  //   //   return mostrarAlerta("Email paypal no es valido ", "error");
  //   // }
  //   if (password !== "") {
  //   } else {
  //   }

  //   setopen(false);

  const handleCrear = async () => {
    const hora = new Date(misa.informacion.fecha).getHours();

    const misaExiste = rowsMisas.find((element) => {
      const row = JSON.parse(element.tabInformacion);
      if (Date.parse(row.fecha) < Date.now()) {
        return null;
      }
      const rowdia = row.fecha.split("T")[0];
      const dia = misa.informacion.fecha.split("T")[0];
      const rowhora = row.fecha.split("T")[1];

      return Number(rowhora.substr(0, 2)) === hora && dia === rowdia;
    });
    if (misaExiste) {
      return mostrarAlerta(
        "Ya existe una misa para la hora estipulada .",
        "warning"
      );
    }

    if (isIsoDate(informacion.fecha, mostrarAlerta, "Fecha")) {
      return;
    }
    socket.emit("guardarData", {
      ...misa,
      informacion: trimPropsItem({
        ...misa.informacion,
        user: usuario.usuario,
      }),
      agencia: usuario.agencia,
      subagencia: usuario.subAgencia,
    });
    // agregarAsignatura(misa);
    defaultActive();
  };
  const handleEditar = () => {
    if (isIsoDate(informacion.fecha, mostrarAlerta, "Fecha")) {
      return;
    }
    socket.emit("actualizarData", {
      ...misa,
      informacion: trimPropsItem({
        ...misa.informacion,
        user: usuario.usuario,
      }),
      tipo: misa.tabTipo,
      agencia: misa.tabAgencia,
      id: misa.tabId,
      subagencia: usuario.subAgencia,
    });
    // editarAsignatura(misa);
    defaultActive();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        // setocultar(!ocultar);
      }}
    >
      <Draggable handle="#handle">
        <Box display="flex" justifyContent="center">
          <div
            style={ocultar ? modalStyleOculto : modalStyle}
            className={clases.paper}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              style={{ cursor: "-webkit-grab" }}
            >
              <Typography
                id="handle"
                variant="button"
                color="initial"
                align="center"
              >
                {editar ? "Editar" : "Agregar"} misa.
              </Typography>
              <Box display="flex" alignItems="center">
                <IconButton
                  aria-label=""
                  style={{ margin: "0px" }}
                  onClick={() => {
                    defaultActive();
                  }}
                >
                  <Cancel color="secondary" />
                </IconButton>
              </Box>
            </Box>

            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Switch
                    name="estado"
                    checked={informacion.estado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setmisa({
                        ...misa,
                        informacion: {
                          ...misa.informacion,
                          estado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        },
                      });
                    }}
                  />
                }
                label="Estado."
              />
              <Box display={"flex"} >
                <Typography variant="body1" color="primary" >
                  Agencia:{" "}
                  <Typography color="secondary" component={"span"}>
                    {usuario.agencia}
                  </Typography>
                </Typography>
                <Box mx={1}></Box>
                <Typography variant="body1" color="primary">
                  Sub-Agencia:{" "}
                  <Typography color="secondary" component={"span"}>
                    {usuario.subAgencia}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              borderRadius="0px 50px 50px 0px"
              mb={2}
              p={1}
            >
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.formD}
                name="cedula"
                label="Cédula"
                value={informacion.cedula}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.formD}
                name="nombres"
                label="Nombres"
                value={informacion.nombres}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <TextField
                size="small"
                onChange={handleChange}
                className={clases.formD}
                name="fecha"
                label={`Fecha ${formato}`}
                value={informacion.fecha}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
              <FormControl className={clases.formD}>
                <InputLabel id="demo-mutiple-chip-label">Modalidad</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  name="modalidad"
                  value={informacion.modalidad}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  MenuProps={MenuProps}
                >
                  {["CUARTETO", "DUO", "PRESENCIAL", "ONLINE"].map((row) => (
                    <MenuItem
                      key={row}
                      value={row}
                      style={getStylesEspecialidades(
                        row,
                        informacion.modalidad,
                        theme
                      )}
                    >
                      {row}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                multiline
                rows={3}
                variant="outlined"
                size="small"
                onChange={handleChange}
                className={clases.estudianteDatos}
                name="observacion"
                label="Observación"
                value={informacion.observacion}
                required
                fullWidth
                inputProps={{
                  className: clases.inputPadding,
                }}
              />
            </Box>
            {/* <Divider /> */}
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={!submit()}
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Draggable>
    </Modal>
  );
};

export default memo(ModalAsignatura);
// {
// 	"tipo": "inhumaciones",
//    "agencia": "MO",
// 	"informacion": {
// 		"cedula": "1716520000",
//       "nombres": "",
// 		"misa": "",
// 		"fecha": "",
// 		"ubicacion": "",
// 		"asesor": "",
// 		"estado": "",
// 		"user": ""
// 	}
// },
