import React, { useReducer, memo } from "react";
import clienteAxios from "../../config/axios";
import {
  OBTENER_CENIZAS,
  AGREGAR_CENIZA,
  EDITAR_CENIZA,
  ELIMINAR_CENIZA,
  CHANGE_CENIZA_ACTIVO,
  CERRAR_SESION,
} from "../../types/index";

//context
import CenizaContext from "./CenizaContext";
//reducer
import CenizasReducer from "./CenizasReducer";
const CenizasState = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "agencia",
        label: "agencia",
        minWidth: 150,
      },
      {
        id: "cedula",
        label: "cedula",
        minWidth: 150,
      },
      {
        id: "fecha",
        label: "fecha",
        minWidth: 150,
      },
      {
        id: "nombres",
        label: "nombres",
        minWidth: 150,
      },
      {
        id: "ubicacion",
        label: "ubicacion",
        minWidth: 150,
      },
      {
        id: "asesor",
        label: "asesor",
        minWidth: 150,
      },
      {
        id: "observacion",
        label: "observacion",
        minWidth: 150,
      },
    ],
    cenizaActiva: {
      tipo: "cenizas",
      agencia: "MO",
      informacion: {
        cedula: "",
        nombres: "",
        fecha: "",
        ubicacion: "",
        asesor: "",
        estado: "ACTIVO",
        user: "",
        idAsesor: "",
        observacion: "",
      },
    },
  };

  const [state, dispatch] = useReducer(CenizasReducer, initialState);

  const obtenerCenizas = async (agencia, subagencia = "") => {
    console.log({
      tipo: "cenizas",
      agencia,
      subagencia,
    });
    const res = await clienteAxios.post("/tablero", {
      tipo: "cenizas",
      agencia,
      subagencia,
    });
    [...res.data].forEach((item) => {
      try {
        JSON.parse(item.tabInformacion);
      } catch (error) {}
    });
    dispatch({
      type: OBTENER_CENIZAS,
      payload: res.data,
    });
  };

  const agregarCeniza = async (ceniza) => {
    try {
      dispatch({
        type: AGREGAR_CENIZA,
        payload: ceniza,
      });
    } catch (error) {}
  };

  const editarCeniza = async (cenizaEditada) => {
    try {
      dispatch({
        type: EDITAR_CENIZA,
        payload: cenizaEditada,
      });
    } catch (error) {}
  };

  const eliminarCeniza = async (id) => {
    try {
      dispatch({
        type: ELIMINAR_CENIZA,
        payload: id,
      });
    } catch (error) {}
  };

  const changeCenizaActiva = (asignatura) => {
    dispatch({
      type: CHANGE_CENIZA_ACTIVO,
      payload: asignatura,
    });
  };
  const cerrarSesionCeniza = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };
  return (
    <CenizaContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        cenizaActiva: state.cenizaActiva,
        // funciones
        obtenerCenizas,
        agregarCeniza,
        editarCeniza,
        eliminarCeniza,
        changeCenizaActiva,
        cerrarSesionCeniza,
      }}
    >
      {props.children}
    </CenizaContext.Provider>
  );
};

export default memo(CenizasState);
