import React, { memo } from "react";
import "./Cargando.css";
import { Typography, Box } from "@material-ui/core";

const Spinner = ({ titulo }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      width="100%"
      p={5}
      m={2}
    >
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
      <br />
      <br />
      <Typography variant="h5" color="initial">
        {titulo}
      </Typography>
    </Box>
  );
};

export default memo(Spinner);
