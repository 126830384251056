/*eslint-disable*/
import React, { memo, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

import {
  Sms,
  MenuTwoTone,
  KeyboardArrowLeftTwoTone,
  ExpandLess,
  ExpandMore,
  ArrowRight,
  SettingsTwoTone,
  MenuBookTwoTone,
  ViewWeekTwoTone,
  PersonTwoTone,
  LocalLibraryTwoTone,
  SupervisorAccountTwoTone,
  ReceiptTwoTone,
  FindInPageTwoTone,
  FormatListNumberedTwoTone,
} from "@material-ui/icons/";
import clsx from "clsx";
// core components
import AdminNavbarLinks from "../../components/Navbars/AdminNavbarLinks.js";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { Box, Collapse, ListItemIcon, Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

const Sidebar = (props) => {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.history.location.pathname.indexOf(routeName) > -1
      ? true
      : false;
  }
  const {
    color,
    logo,
    image,
    logoText,
    routes,
    categorias,
    drawerOpen,
    handleDrawerOpen,
    handleDrawerClose,
    rowsSubCategorias,
    rowsSub_Subcategorias,
  } = props;
  const [open, setOpen] = React.useState({
    configuraciones: true,
    malla: false,
    menu: false,
    personal: false,
    notas: false,
    administracion: false,
    reportes: false,
    facturacion: false,
    biblioteca: false,
  });
  const {
    configuraciones,
    malla,
    menu,
    personal,
    notas,
    administracion,
    reportes,
    facturacion,
    biblioteca,
  } = open;
  const handleClick = (e, a) => {
    setOpen({
      ...open,
      [e]: !a,
    });
  };

  var brand = (
    <div className={classes.logo}>
      <div className={classNames(classes.logoLink, {})}>tablero monteolivo</div>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "right" : "right"}
          open={props.open}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            <List component="nav" className={classes.root + classes.white}>
              {routes.map((prop, key) => {
                if (prop.tipo !== "main") return null;

                var activePro = " ";
                var listItemClasses;
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
                const whiteFontClasses = classNames({
                  [" " + classes.whiteFont]: activeRoute(
                    prop.layout + prop.path
                  ),
                });

                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    activeClassName="active"
                    key={key}
                    className={classes.itemLink + listItemClasses}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        {typeof prop.icon === "string" ? (
                          <Icon
                            className={classNames(
                              classes.itemIcon,
                              whiteFontClasses,
                              {}
                            )}
                          >
                            {prop.icon}
                          </Icon>
                        ) : (
                          <prop.icon
                            className={classNames(
                              classes.itemIcon,
                              whiteFontClasses,
                              {}
                            )}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={props.rtlActive ? prop.rtlName : prop.name}
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {}
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              })}
            </List>
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "left" : "left"}
          variant="permanent"
          open
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
        >
          {drawerOpen ? (
            <Box display="flex" alignItems="center">
              <div>
                <div className={classes.logo}>
                  <div className={classNames(classes.logoLink, {})}>
                    <Box
                      style={{
                        cursor: "grab",
                      }}
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <NavLink
                        color="secondary"
                        className={classes.link}
                        to={"/monteolivo/Servicios"}
                      >
                        <Typography
                          align="center"
                          style={{ width: "10rem", fontSize: "1rem" }}
                          color="secondary"
                        >
                          tablero
                        </Typography>
                        <Typography
                          align="center"
                          style={{ width: "10rem", fontSize: "1rem" }}
                          color="secondary"
                        >
                          monteolivo
                        </Typography>
                      </NavLink>

                      <div
                        className={classes.logoImage}
                        onClick={handleDrawerClose}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          height="100%"
                          alignItems="center"
                        >
                          <ArrowRight className={classes.icons} />
                        </Box>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>
          ) : (
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
              m={1}
            >
              <div className={classes.logo} onClick={handleDrawerOpen}>
                <div className={classNames(classes.logoLink, {})}>
                  <div className={classes.logoImage}>
                    <MenuTwoTone className={classes.icons} />
                  </div>
                </div>
              </div>
            </Box>
          )}
          <div className={classes.sidebarWrapper}>
            {" "}
            <List component="nav" className={classes.root + classes.white}>
              {routes.map((prop, key) => {
                if (prop.tipo !== "main") return null;

                var activePro = " ";
                var listItemClasses;
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
                const whiteFontClasses = classNames({
                  [" " + classes.whiteFont]: activeRoute(
                    prop.layout + prop.path
                  ),
                });

                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    activeClassName="active"
                    key={key}
                    className={classes.itemLink + listItemClasses}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        {typeof prop.icon === "string" ? (
                          <Icon
                            className={classNames(
                              classes.itemIcon,
                              whiteFontClasses,
                              {}
                            )}
                          >
                            {prop.icon}
                          </Icon>
                        ) : (
                          <prop.icon
                            className={classNames(
                              classes.itemIcon,
                              whiteFontClasses,
                              {}
                            )}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={props.rtlActive ? prop.rtlName : prop.name}
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {}
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              })}
            </List>
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

export default memo(Sidebar);
