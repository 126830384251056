import React, { memo, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";

import { Delete, Create } from "@material-ui/icons";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../Extra/ConfirmacionDialog";

import ServiciosContext from "../../../Context/Servicios/ServiciosContext";
import { useEffect } from "react";
import AuthContext from "../../../Context/Auth/AuthContext";
import { fechaEspañol } from "../../../config/validaciones";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const { row, index, setopen, seteditar } = props;

  const { changeServicioActivo } = useContext(ServiciosContext);

  const classes = useRowStyles();
  const { socket, usuario } = useContext(AuthContext);
  const handleEliminar = async (_id) => {
    socket.emit("eliminarData", {
      id: row.tabId,
      tipo: row.tabTipo,
    });
  };
  useEffect(() => {
    setParseRow(JSON.parse(row.tabInformacion));
  }, [row]);
  const [parseRow, setParseRow] = useState(JSON.parse(row.tabInformacion));
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  if (usuario.agencia !== row.tabAgencia) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro de que quiere eliminar esta servicio?"
        contenido={`La servicio   ${parseRow.nombres} , una vez borrado no podra ser recuperado`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}

        <TableCell
          style={{ borderRight: "1px solid #ccc", paddingRight: "3rem" }}
        >
          <Box display="flex" alignItems="center" mr={4}>
            <IconButton
              className={classes.margin}
              style={{ position: "absolute" }}
              onClick={() => setopenConfirmDialog(true)}
            >
              <Delete fontSize="small" color="secondary" />
            </IconButton>

            <IconButton
              className={classes.margin}
              style={{ position: "absolute", marginLeft: "1.5rem" }}
              onClick={() => {
                changeServicioActivo({
                  ...row,
                  informacion: parseRow,
                });
                seteditar(true);
                setopen(true);
              }}
            >
              <Create fontSize="small" color="secondary" />
            </IconButton>
            <FormControlLabel
              className={classes.margin}
              style={{ position: "absolute", marginLeft: "2.5rem" }}
              control={
                <Checkbox
                  value="ACTIVO"
                  checked={parseRow.estado === "ACTIVO" ? true : false}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>

        {/* las demas columnas */}
        <TableCell size="small" align="center">
          {row.tabAgencia}
        </TableCell>
        <TableCell size="small" align="center">
          {parseRow.cedula}
        </TableCell>
        <TableCell size="small" align="center">
          {parseRow.nombres}
        </TableCell>

        <TableCell size="small" align="center">
          {parseRow.servicio}
        </TableCell>

        <TableCell size="small" align="center">
          {parseRow.sala}
        </TableCell>
        <TableCell
          size="small"
          align="center"
          style={{
            color: parseRow.inicio === "" ? "#D26161" : "",
          }}
        >
          {parseRow.inicio === ""
            ? "SIN CONFIRMAR"
            : fechaEspañol(parseRow.inicio)}
        </TableCell>
        <TableCell
          size="small"
          align="center"
          style={{
            color: parseRow.misa === "" ? "#D26161" : "",
          }}
        >
          {parseRow.misa === "" ? "SIN CONFIRMAR" : fechaEspañol(parseRow.misa)}
        </TableCell>

        <TableCell size="small" align="center">
          {parseRow.ubicacion}
        </TableCell>

        <TableCell size="small" align="center">
          {parseRow.asesor}
        </TableCell>
        <TableCell size="small" align="center">
          {parseRow.observacion}
        </TableCell>
        {/* <TableCell  >{row.edad}</TableCell> */}
        {/* <TableCell  >{row.codigo}</TableCell> */}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(Row);
